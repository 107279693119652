import db from '../../SchoolAccessDatabase';
import { Student } from '@/api/model/Student';
import { MutationBase } from '../MutationBase';
import { calculateUsage } from '@/api/model/UnitUsage';
import { EntityType, MutationType } from '../IMutation';
import { StudentMutator } from '@/api/model/StudentMutator';
import { API } from '@/api/service/APIAccess';
import { PresenceState } from '@/api/model/PresenceState';
import { PaymentMethod } from '@/api/model/PaymentMethod';
import { SubmittedDocumentType } from '@/api/model/SubmittedDocumentType';
import { Experience } from '@/api/model/Experience';
import Badge from '@/api/model/Badge';

export abstract class StudentMutationBase extends MutationBase<Student> {
  public constructor(
    id: string,
    type: MutationType,
    entityId: string,
    entityType: EntityType,
    entityName: string,
    date: number,
    message: string,
    isSynchronized: boolean,
    data: unknown
  ) {
    super(id, type, entityId, entityType, entityName, date, message, isSynchronized, data);
  }

  protected performInTransactionScope(action: () => Promise<Student>): Promise<Student> {
    return db.performInStudentMutationTransaction(action);
  }

  protected getEntity(): Promise<Student> {
    return db.getStudentById(this.entityId);
  }
  protected updateEntity(entity: Student): Promise<void> {
    return db.updateStudent(entity);
  }

  protected updateAfterSave(entity: Student): void {
    entity.unitUsage = calculateUsage(entity);
  }
}

interface UpdateCovidDocument {
  submitDate: Date;
  documentType: SubmittedDocumentType;
}

export class StudentCovidDocumentMutation extends StudentMutationBase {
  public static create(
    id: string,
    studentId: string,
    studentName: string,
    message: string,
    data: unknown
  ): StudentCovidDocumentMutation {
    return new StudentCovidDocumentMutation(
      id,
      MutationType.CovidDocument,
      studentId,
      EntityType.Student,
      studentName,
      Date.now(),
      message,
      false,
      data
    );
  }
  public static createFromDB(
    id: string,
    studentId: string,
    studentName: string,
    date: number,
    message: string,
    isSynchronized: boolean,
    data: unknown
  ): StudentCovidDocumentMutation {
    return new StudentCovidDocumentMutation(
      id,
      MutationType.CovidDocument,
      studentId,
      EntityType.Student,
      studentName,
      date,
      message,
      isSynchronized,
      data
    );
  }

  protected performAction(student: Student): void {
    const data = this.data as UpdateCovidDocument;
    StudentMutator.addSubmittedDocument(student, data.submitDate, data.documentType);
  }
  protected performUndo(student: Student): void {
    const data = this.data as UpdateCovidDocument;
    StudentMutator.removeSubmittedDocument(student, data.submitDate, data.documentType);
  }

  protected performsynchronize(): Promise<boolean> {
    const data = this.data as UpdateCovidDocument;

    return API.studentAddSubmittedDocuement(this.entityId, data.submitDate, data.documentType);
  }
}

interface UpateFloaty {
  originalFloatyCount: number;
  floatyCount: number;
}

export class StudentFloatyMutation extends StudentMutationBase {
  public static create(
    id: string,
    studentId: string,
    studentName: string,
    message: string,
    data: unknown
  ): StudentFloatyMutation {
    return new StudentFloatyMutation(
      id,
      MutationType.FloatyCount,
      studentId,
      EntityType.Student,
      studentName,
      Date.now(),
      message,
      false,
      data
    );
  }
  public static createFromDB(
    id: string,
    studentId: string,
    studentName: string,
    date: number,
    message: string,
    isSynchronized: boolean,
    data: unknown
  ): StudentFloatyMutation {
    return new StudentFloatyMutation(
      id,
      MutationType.FloatyCount,
      studentId,
      EntityType.Student,
      studentName,
      date,
      message,
      isSynchronized,
      data
    );
  }

  protected performAction(student: Student): void {
    const data = this.data as UpateFloaty;
    StudentMutator.setFloaty(student, data.floatyCount);
  }

  protected performUndo(student: Student): void {
    const data = this.data as UpateFloaty;

    StudentMutator.setFloaty(student, data.originalFloatyCount);
  }

  protected async performsynchronize(): Promise<boolean> {
    const data = this.data as UpateFloaty;

    return await API.studentUpdateFloaty(this.entityId, data.floatyCount);
  }
}

export interface StudentUpdatePayment {
  paymentType: PaymentMethod;
  paidUnits: string[];
}

export class StudentPaymentMutation extends StudentMutationBase {
  public static create(
    id: string,
    studentId: string,
    studentName: string,
    message: string,
    data: unknown
  ): StudentPaymentMutation {
    return new StudentPaymentMutation(
      id,
      MutationType.Payment,
      studentId,
      EntityType.Student,
      studentName,
      Date.now(),
      message,
      false,
      data
    );
  }
  public static createFromDB(
    id: string,
    studentId: string,
    studentName: string,
    date: number,
    message: string,
    isSynchronized: boolean,
    data: unknown
  ): StudentPaymentMutation {
    return new StudentPaymentMutation(
      id,
      MutationType.Payment,
      studentId,
      EntityType.Student,
      studentName,
      date,
      message,
      isSynchronized,
      data
    );
  }
  protected performAction(student: Student): void {
    const data = this.data as StudentUpdatePayment;
    StudentMutator.payUnits(student, data.paidUnits, data.paymentType);
  }

  protected performUndo(student: Student): void {
    const data = this.data as StudentUpdatePayment;
    StudentMutator.payUnits(student, data.paidUnits, PaymentMethod.None);
  }

  protected async performsynchronize(): Promise<boolean> {
    const data = this.data as StudentUpdatePayment;

    let result = true;
    for (const unitId of data.paidUnits) {
      result &&= await API.studentUpdatePaidUnit(
        this.entityId,
        unitId,
        this.date,
        data.paymentType
      );
    }

    return result;
  }
}

interface UpdatePresence {
  courseId: string;
  date: Date;
  presence: PresenceState;
  originalPresence: PresenceState;
}

export class StudentPresenceMutation extends StudentMutationBase {
  public static create(
    id: string,
    studentId: string,
    studentName: string,
    message: string,
    data: unknown
  ): StudentPresenceMutation {
    return new StudentPresenceMutation(
      id,
      MutationType.Present,
      studentId,
      EntityType.Student,
      studentName,
      Date.now(),
      message,
      false,
      data
    );
  }

  public static createFromDB(
    id: string,
    studentId: string,
    studentName: string,
    date: number,
    message: string,
    isSynchronized: boolean,
    data: unknown
  ): StudentPresenceMutation {
    return new StudentPresenceMutation(
      id,
      MutationType.Present,
      studentId,
      EntityType.Student,
      studentName,
      date,
      message,
      isSynchronized,
      data
    );
  }

  protected performAction(student: Student): void {
    const data = this.data as UpdatePresence;
    StudentMutator.setPresence(student, data.courseId, data.date.getTime(), data.presence);
  }
  protected performUndo(student: Student): void {
    const data = this.data as UpdatePresence;
    StudentMutator.setPresence(student, data.courseId, data.date.getTime(), data.originalPresence);
  }

  protected performsynchronize(): Promise<boolean> {
    const data = this.data as UpdatePresence;

    return API.studentUpdatePresence(
      this.entityId,
      data.courseId,
      new Date(data.date),
      data.presence
    );
  }
}

interface UpdateBadges {
  badges: Badge[];
  originalBadges: Badge[];
}

export class StudentBadgeMutation extends StudentMutationBase {
  public static create(
    id: string,
    studentId: string,
    studentName: string,
    message: string,
    data: unknown
  ): StudentBadgeMutation {
    return new StudentBadgeMutation(
      id,
      MutationType.Badge,
      studentId,
      EntityType.Student,
      studentName,
      Date.now(),
      message,
      false,
      data
    );
  }

  public static createFromDB(
    id: string,
    studentId: string,
    studentName: string,
    date: number,
    message: string,
    isSynchronized: boolean,
    data: unknown
  ): StudentBadgeMutation {
    return new StudentBadgeMutation(
      id,
      MutationType.Badge,
      studentId,
      EntityType.Student,
      studentName,
      date,
      message,
      isSynchronized,
      data
    );
  }

  protected performAction(student: Student): void {
    const data = this.data as UpdateBadges;
    student.badges = data.badges;
  }

  protected performUndo(student: Student): void {
    const data = this.data as UpdateBadges;
    student.badges = data.originalBadges;
  }

  protected performsynchronize(): Promise<boolean> {
    const data = this.data as UpdateBadges;

    return API.studentUpdateBadges(this.entityId, data.badges);
  }
}
