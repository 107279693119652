

















































import { Component, Vue } from 'vue-property-decorator';
import MutationFactory from '@/localCache/mutations/MutationFactory';
import { StudentViewModel } from '@/model/StudentViewModel';
import SchoolCache from '@/localCache/SchoolCache';
import { Experience } from '@/api/model/Experience';

@Component({
  name: 'FloatyDialog',
})
export default class FloatyDialog extends Vue {
  private showDialog = false;
  private isAdult = false;

  private student?: StudentViewModel;
  private floatyCountSelected = 0;

  private showDialogPromiseResolve?: (value?: void | PromiseLike<void> | undefined) => void;

  public show(student: StudentViewModel): Promise<void> {
    this.student = student;

    if (student.studentData.floatCount !== undefined && student.studentData.floatCount !== null) {
      this.floatyCountSelected = student.studentData.floatCount;
      this.isAdult = false;
    } else {
      this.isAdult = true;
    }

    this.showDialog = true;
    return new Promise(resolve => {
      this.showDialogPromiseResolve = resolve;
    });
  }

  private async saveAndClose(): Promise<void> {
    if (!this.student) return;

    const existingMutation = await SchoolCache.getMutationById(
      MutationFactory.getStudentFloatyMutationId(this.student.studentData)
    );

    const mutation = !existingMutation
      ? MutationFactory.CreateStudentFloatyMutation(
          this.student.studentData,
          this.floatyCountSelected
        )
      : MutationFactory.CreateStudentFloatyMutationFromExisting(
          this.student.studentData,
          existingMutation.data,
          this.floatyCountSelected
        );

    await SchoolCache.removeMutationById(mutation.id);
    await SchoolCache.addMutation(mutation);
    this.close();
  }

  private close(): void {
    if (this.showDialogPromiseResolve === null || this.showDialogPromiseResolve === undefined)
      return;

    this.showDialog = false;
    this.showDialogPromiseResolve();
  }
}
