


























































































































































import { Component, Vue } from 'vue-property-decorator';
import StudentDetailDialog from '../components/StudentDetailDialog.vue';
import StudentPresenceDialog from '../components/StudentPresenceDialog.vue';
import DatePickerDialog from '../components/DatePickerDialog.vue';
import FloatyDialog from '../components/FloatyDialog.vue';
import BadgeDialog from '../components/BadgeDialog.vue';
import PresenceSwitch from '../components/PresenceSwitch.vue';
import PaidCourseDialog from '../components/PaidCourseDialog.vue';

import Vue2Filters from 'vue2-filters';
import 'moment-duration-format';
import moment, { Duration } from 'moment';
import schoolCache from '../localCache/SchoolCache';
import { PresenceStudentViewModel } from '../model/PresenceStudentViewModel';
import _ from 'lodash';
import { globalEvent } from '@billjs/event-emitter';
import { Student } from '@/api/model/Student';
import { Experience, getExperienceString } from '@/api/model/Experience';
import { BadgeType, getBadgeString } from '@/api/model/BadgeType';

@Component({
  name: 'CourseView',
  mixins: [Vue2Filters.mixin],
  components: {
    StudentDetailDialog,
    StudentPresenceDialog,
    DatePickerDialog,
    PresenceSwitch,
    FloatyDialog,
    PaidCourseDialog,
    BadgeDialog,
  },
  filters: {
    formatDate(value: unknown) {
      if (!value) {
        return '';
      }
      if (value instanceof Date) {
        return moment(value).format('DD.MM.YY');
      }
    },
    formatTime(value: unknown) {
      if (!value) {
        return '';
      }
      if (moment.isDuration(value)) {
        return value.format('hh:mm');
      }
    },
    formatExperienceString(value: unknown) {
      return getExperienceString(value as Experience);
    },
    formatBadgeString(value: unknown) {
      return getBadgeString(value as BadgeType);
    },
  },
})
export default class CourseView extends Vue {
  $refs!: {
    studentDetailDialog: StudentDetailDialog;
    studentPresenceDialog: StudentPresenceDialog;
    datePickerDialog: DatePickerDialog;
    floatyDialog: FloatyDialog;
    paidCourseDialog: PaidCourseDialog;
    badgeDialog: BadgeDialog;
  };

  private updatingDate = false;
  private showSelectDialog = false;
  private selectedDate: Date = new Date(Date.now());
  private allStudents: PresenceStudentViewModel[] = [];
  private students: PresenceStudentViewModel[] = [];
  private search = '';

  private pools: string[] = [];
  private selectedPools: number[] = [];
  private poolIsIndeterminate = false;
  private poolAllSelected = false;

  private startTimes: { str: string; time: Duration }[] = [];
  private selectedStartTimes: number[] = [];
  private timeIsIndeterminate = false;
  private timeAllSelected = false;

  private get headers() {
    return [
      { text: 'Name', align: 'start', value: 'student.name' },
      { text: 'Anwesend', value: 'presence' },
      { text: 'Schimm Hilfen', value: 'student.studentData.floatCount' },
      { text: 'Abzeichen', value: 'student.studentData.badge' },
      { text: 'Startzeit', value: 'course.startTime' },
      { text: 'Bezahlt', value: 'student.amount' },
      { text: 'Einheiten', value: 'student.remainedSessionCount' },
      { text: 'Kurse', value: 'student.courseCount' },
      /*  {
        text: 'Covid',
        value: 'covidDocuments.sortablelastSubmittedCovid19Document',
      },
      */
      { text: 'Actions', value: 'action', sortable: false, align: 'right' },
    ];
  }

  private getUnitColor(payedUnits: number) {
    if (payedUnits <= 0) return 'red';
    else if (payedUnits <= 2) return 'orange';
    else return 'transparent ';
  }

  private async activated(): Promise<void> {
    await this.updateSelection(this.selectedDate);
  }

  private async selectDate(): Promise<void> {
    return this.$refs.datePickerDialog.show().then(selectedDate => {
      if (selectedDate !== undefined) this.updateSelection(selectedDate);
    });
  }

  private async updateSelection(selectedDate: Date): Promise<void> {
    this.selectedDate = selectedDate;

    globalEvent.offAll();
    this.allStudents = await schoolCache.getPresenceStudents(selectedDate);

    console.log(this.allStudents);

    this.startTimes = _(this.allStudents)
      .map(s => s.course.startTime)
      .map(t => {
        return { str: t.format('hh:mm'), time: t };
      })
      .uniqBy(t => t.str)
      .sortBy(t => t.time.asMilliseconds())
      .value();

    this.pools = _(this.allStudents)
      .map(s => s.courseInfo.name)
      .uniq()
      .filter(s => !!s)
      .sortBy(t => t)
      .value();

    this.selectedPools = [...Array(this.pools.length).keys()];
    this.selectedStartTimes = [];
    this.updateFilter();
  }

  private async showPresence(student: PresenceStudentViewModel): Promise<void> {
    await this.$refs.studentPresenceDialog.show(student.student);
  }

  private async showFloatCount(student: PresenceStudentViewModel): Promise<void> {
    await this.$refs.floatyDialog.show(student.student);
  }

  private async showBadgeInput(student: PresenceStudentViewModel): Promise<void> {
    await this.$refs.badgeDialog.show(student.student);
  }

  private async showPayDialog(student: PresenceStudentViewModel): Promise<void> {
    await this.$refs.paidCourseDialog.show(student.student);
  }
  private SubmitCovidDocument(student: PresenceStudentViewModel): Promise<void> {
    return student.toggleSubmittedCovidDocument();
  }

  private updateFilter(): void {
    const selectedPools = _(this.selectedPools)
      .map(s => this.pools[s])
      .value();

    const selectedTime = _(this.selectedStartTimes)
      .map(s => this.startTimes[s].str)
      .value();

    this.timeAllSelected = this.selectedStartTimes.length === this.startTimes.length;
    this.timeIsIndeterminate = !this.timeAllSelected && this.selectedStartTimes.length > 0;
    this.poolAllSelected = this.selectedPools.length === this.pools.length;
    this.poolIsIndeterminate = !this.poolAllSelected && this.selectedPools.length > 0;

    this.students = _(this.allStudents)
      .filter(s => this.pools.length === 0 || selectedPools.includes(s.courseInfo.name))
      .filter(s => this.startTimes.length === 0 || selectedTime.includes(s.courseStartTime))
      .value();
  }

  private poolAllChanged(allSelected: boolean) {
    this.selectedPools = allSelected ? [...Array(this.pools.length).keys()] : [];
    this.updateFilter();
  }
  private timeAllChanged(allSelected: boolean) {
    this.selectedStartTimes = allSelected ? [...Array(this.startTimes.length).keys()] : [];

    this.updateFilter();
  }

  private async showDetails(student: PresenceStudentViewModel): Promise<void> {
    await this.$refs.studentDetailDialog.show(student.student);
  }

  private isChild(student: Student): boolean {
    return student.sizeOfShoe !== undefined;
  }
}
