import store from '../index';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'alertModule',
  store,
})
class AlertModule extends VuexModule {
  public message = '';
  public showNotification = false;
  public showErrorMessage = false;

  @Action
  public showSuccess(message: string): void {
    this.context.commit('success', message);
  }

  @Action
  public showError(message: string): void {
    this.context.commit('error', message);
  }

  @Action
  public clearMessage(): void {
    this.context.commit('clear');
  }

  @Mutation
  public success(message: string): void {
    this.message = message;
    this.showErrorMessage = false;
    this.showNotification = true;
  }

  @Mutation
  public error(message: string): void {
    this.message = message;
    this.showErrorMessage = true;
    this.showNotification = false;
  }

  @Mutation
  public clear(): void {
    this.showNotification = false;
    this.showErrorMessage = false;
  }
}

export default getModule(AlertModule);
