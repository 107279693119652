var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('student-detail-dialog',{ref:"studentDetailDialog"}),_c('student-presence-dialog',{ref:"studentPresenceDialog"}),_c('floaty-dialog',{ref:"floatyDialog"}),_c('paid-course-dialog',{ref:"paidCourseDialog"}),_c('v-text-field',{attrs:{"clearable":"","append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.students,"item-key":"studentData.id"},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{class:{ orange: item.amount !== 0 },attrs:{"text":""},on:{"click":function($event){return _vm.showPayDialog(item)}}},[(item.amount === 0)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-bold ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.amount,'€', 0, { decimalSeparator: ',' }))+" ")])],1)]}},{key:"item.remainedSessionCount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"sameWidth",attrs:{"color":_vm.getUnitColor(item.remainedSessionCount),"dark":"","outlined":"","text-color":"black"},on:{"click":function($event){return _vm.showPresence(item)}}},[_vm._v(" "+_vm._s(item.remainedSessionCount)+" ")])]}},{key:"item.covidDocuments.sortablelastSubmittedCovid19Document",fn:function(ref){
var item = ref.item;
return [(item.covidDocuments.lastSubmittedCovid19DocumentValid)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-bold ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-thick ")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(item.covidDocuments.lastSubmittedCovid19Document))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showDetails(item)}}},[_c('v-icon',[_vm._v("mdi-account-details-outline")])],1)]}},{key:"item.studentData.floatCount",fn:function(ref){
var item = ref.item;
return [(item.studentData.floatCount >= 0)?_c('v-btn',{staticClass:"normalBtn",attrs:{"text":""},on:{"click":function($event){return _vm.showFloatCount(item)}}},[_c('div',{staticClass:"d-flex justify-space-between text-caption"},[_vm._v(" "+_vm._s(item.studentData.floatCount)+" ")])]):_c('label',[_vm._v("Keine")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }