












































































import { StudentViewModel } from '../model/StudentViewModel';
import { Component, Vue } from 'vue-property-decorator';
import schoolCache from '../localCache/SchoolCache';
import StudentDetailDialog from '../components/StudentDetailDialog.vue';
import StudentPresenceDialog from '../components/StudentPresenceDialog.vue';
import PaidCourseDialog from '../components/PaidCourseDialog.vue';
import FloatyDialog from '../components/FloatyDialog.vue';
import Vue2Filters from 'vue2-filters';
import moment from 'moment';
import { Experience, getExperienceString } from '@/api/model/Experience';
import { Student } from '@/api/model/Student';

@Component({
  name: 'AllStudentsView',
  components: {
    StudentDetailDialog,
    StudentPresenceDialog,
    FloatyDialog,
    PaidCourseDialog,
  },
  mixins: [Vue2Filters.mixin],
  filters: {
    formatDate(value: unknown) {
      if (value === undefined) {
        return '';
      }
      if (value instanceof Date) {
        return moment(value).format('DD.MM.YY');
      }
    },
    formatExperienceString(value: unknown) {
      return getExperienceString(value as Experience);
    },
  },
})
export default class AllStudentsView extends Vue {
  $refs!: {
    studentDetailDialog: StudentDetailDialog;
    studentPresenceDialog: StudentPresenceDialog;
    floatyDialog: FloatyDialog;
    paidCourseDialog: PaidCourseDialog;
  };
  public students: StudentViewModel[] = [];
  public selectedStudent?: StudentViewModel;
  public showDetailDialog = false;
  public search = '';

  public get headers(): unknown {
    return [
      { text: 'Vorname', align: 'start', value: 'firstname' },
      { text: 'Nachname', value: 'lastname' },
      { text: 'Schimm Hilfen', value: 'studentData.floatCount' },

      { text: 'Bezahlt', value: 'amount' },
      { text: 'Einheiten Übrig', value: 'remainedSessionCount' },
      {
        text: 'Covid Dokumente',
        value: 'covidDocuments.sortablelastSubmittedCovid19Document',
      },
      { text: 'Actions', value: 'action', sortable: false, align: 'right' },
    ];
  }

  private getUnitColor(payedUnits: number) {
    if (payedUnits <= 0) return 'red';
    else if (payedUnits <= 2) return 'orange';
    else return 'transparent ';
  }

  private async created(): Promise<void> {
    this.students = await schoolCache.getAllStudents();
  }

  private async showPresence(student: StudentViewModel): Promise<void> {
    await this.$refs.studentPresenceDialog.show(student);
  }
  private async showDetails(student: StudentViewModel): Promise<void> {
    await this.$refs.studentDetailDialog.show(student);
  }
  private async showFloatCount(student: StudentViewModel): Promise<void> {
    await this.$refs.floatyDialog.show(student);
  }
  private async showPayDialog(student: StudentViewModel): Promise<void> {
    await this.$refs.paidCourseDialog.show(student);
  }
}
