import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import alert from './store/modules/alert';
import moment from 'moment';

Vue.config.productionTip = false;

Vue.filter('formatDate', function (value: unknown) {
  if (value === undefined) {
    return '';
  }
  if (value instanceof Date) {
    return moment(value).format('DD.MM.YY');
  }

  return moment(value as Date).format('DD.MM.YY');
});

Vue.filter('formatTimeOfDate', function (value: unknown) {
  if (value === undefined) {
    return '';
  }

  return moment(value as Date).format('HH:mm');
});

import Vue2Filters from 'vue2-filters';

Vue.use(Vue2Filters);
Vue.config.errorHandler = err => {
  alert.showError(err.message);
};

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
