import TakeSession from '../api/model/TakeSession';
import { PresenceState } from '../api/model/PresenceState';

export class TakeSessionModel {
  public unitNumber: number;
  public dateTime: Date;
  public sortableDate: number;
  public presence: string;

  constructor(ts: TakeSession) {
    this.unitNumber = ts.unitNumber;
    this.dateTime = ts.dateTime;
    this.sortableDate = ts.dateTime.getTime();

    if (ts.isSkipped) this.presence = 'Ausgefallen';
    else {
      switch (ts.presence) {
        case PresenceState.No:
          this.presence = 'Nein';
          break;
        case PresenceState.Yes:
          this.presence = 'Ja';
          break;
        default:
          this.presence = 'N/A';
          break;
      }
    }
  }
}
