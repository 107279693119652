import moment from 'moment';
import * as _ from 'lodash';
import { NonMergingIntervalDateSet } from './NonMergingIntervalDateSet';
import SubmittedDocument from '../api/model/SubmittedDocument';
import { SubmittedDocumentType } from '../api/model/SubmittedDocumentType';
import { Interval } from '@/api/model/Interval';

const validDays = 7;

export class Covid19DocumentManager {
  public lastSubmittedCovid19Document: Date | undefined;
  public lastSubmittedCovid19DocumentValid = false;
  private covid19DocumentValidSet = new NonMergingIntervalDateSet();

  public get sortablelastSubmittedCovid19Document(): number | undefined {
    if (this.lastSubmittedCovid19Document === undefined) return undefined;

    return this.lastSubmittedCovid19Document.getTime();
  }

  public isLastCovidDocumentValid(d: Date): boolean {
    if (this.lastSubmittedCovid19Document === undefined) return false;

    const diff = moment.duration(d.getTime() - this.lastSubmittedCovid19Document.getTime());
    return diff.days() < validDays;
  }

  public isCovidDocmentAvaliable(date: Date): boolean {
    return this.covid19DocumentValidSet.include(date);
  }

  public covidDocmentValidUntil(date: Date): Date | undefined {
    const interval = this.covid19DocumentValidSet.includingInterval(date);
    if (interval === undefined) return undefined;

    return new Date(interval.to);
  }
  public covidDocmentLastSubmitted(date: Date): Date | undefined {
    const interval = this.covid19DocumentValidSet.includingInterval(date);
    if (interval !== undefined) return new Date(interval.from);

    const time = date.getTime();
    const result = _.orderBy(this.covid19DocumentValidSet.entities, ['to'], ['desc']).find(
      i => i.to < time
    );
    if (result === undefined) return undefined;

    return new Date(result.from);
  }

  public getDocumentValidInterval(date: Date): Interval<number> | undefined {
    return this.covid19DocumentValidSet.includingInterval(date);
  }

  public Update(submittedDocuments: SubmittedDocument[]): void {
    if (submittedDocuments === undefined) submittedDocuments = [];

    this.covid19DocumentValidSet = new NonMergingIntervalDateSet();
    try {
      const covidDocs = _.filter(
        submittedDocuments,
        s => s.documentType === SubmittedDocumentType.Covid19Document
      ).sort((a, b) => b.date.getTime() - a.date.getTime());
      this.lastSubmittedCovid19Document = covidDocs.length > 0 ? covidDocs[0].date : undefined;
      _.forEach(covidDocs, c => {
        const start = moment(c.date).startOf('day');
        const end = start.clone().add(validDays - 1, 'days');

        this.covid19DocumentValidSet.add(
          new Interval(start.toDate().getTime(), end.toDate().getTime())
        );
      });
    } catch {
      return;
    }

    this.lastSubmittedCovid19DocumentValid = this.isCovidDocmentAvaliable(new Date(Date.now()));
  }
}
