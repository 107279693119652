










import { PresenceState } from '@/api/model/PresenceState';
import { PresenceStudentViewModel } from '@/model/PresenceStudentViewModel';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'PresenceSwitch',
})
export default class PresenceSwitch extends Vue {
  private selected = 2;
  @Prop() readonly student!: PresenceStudentViewModel;

  private beforeMount(): void {
    switch (this.student.presence) {
      case PresenceState.Yes: {
        this.selected = 0;
        break;
      }
      case PresenceState.No: {
        this.selected = 1;
        break;
      }
      case PresenceState.Unknown: {
        this.selected = 2;
        break;
      }
    }
  }

  private updatePresence(state: PresenceState): Promise<void> {
    return this.student.updatePresence(state);
  }
}
