export enum BadgeType {
  None = 'None',
  Custom = 'Custom',

  Seehorse = 'Seehorse',
  Bronce = 'Bronce',
  Silver = 'Silver',
  Gold = 'Gold',

  SkullOneHour = 'SkullOneHour',
  SkullOneHalfHour = 'SkullOneHalfHour',
  SkullTwoHour = 'SkullTwoHour',
}

const BadgeNames = new Map<BadgeType, string>([
  [BadgeType.None, 'Keins'],
  [BadgeType.Custom, 'Custom'],
  [BadgeType.Seehorse, 'Seepferd.'],
  [BadgeType.Bronce, 'Bronze'],
  [BadgeType.Silver, 'Silber'],
  [BadgeType.Gold, 'Gold'],
  [BadgeType.SkullOneHour, 'Totenkopf 1h'],
  [BadgeType.SkullOneHalfHour, 'Totenkopf 1.5h'],
  [BadgeType.SkullTwoHour, 'Totenkopf 2h'],
]);

export const BadgesOrder = [
  BadgeType.None,
  BadgeType.Custom,
  BadgeType.Seehorse,
  BadgeType.Bronce,
  BadgeType.Silver,
  BadgeType.Gold,
  BadgeType.SkullOneHour,
  BadgeType.SkullOneHalfHour,
  BadgeType.SkullTwoHour,
];

export function getBadgeString(e: BadgeType): string {
  let name = BadgeNames.get(e);
  if (!name) name = BadgeNames.get(BadgeType[e]);
  if (!name) return 'Nicht definiert.';

  return name;
}
