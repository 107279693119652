























import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'ConfirmDialog',
})
export default class ConfirmDialog extends Vue {
  private message = '';
  private showDialog = false;
  private showDialogPromiseResolve?: (value: boolean | PromiseLike<boolean>) => void;

  public show(message: string): Promise<boolean> {
    this.message = message;
    this.showDialog = true;

    return new Promise(resolve => {
      this.showDialogPromiseResolve = resolve;
    });
  }

  private Yes(): void {
    this.closeWithResult(true);
  }
  private No(): void {
    this.closeWithResult(false);
  }

  private closeWithResult(result: boolean): void {
    if (this.showDialogPromiseResolve === null || this.showDialogPromiseResolve === undefined)
      return;

    this.showDialog = false;
    this.showDialogPromiseResolve(result);
  }
}
