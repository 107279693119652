export enum MutationType {
  Present,
  FloatyCount,
  Payment,
  CovidDocument,
  Badge,
}

export enum EntityType {
  Student,
  Trainer,
}

export interface IMutation {
  readonly id: string;
  readonly entityId: string;
  readonly entityType: EntityType;

  readonly date: Date;
  readonly sortDate: number;
  readonly entityName: string;
  readonly message: string;
  readonly data: unknown;
  readonly mutationType: MutationType;

  isSynchronized: boolean;

  add(): Promise<unknown>;
  remove(): Promise<unknown>;
  synchronize(): Promise<void>;
}
