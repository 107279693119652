




















































import { Component, Vue } from 'vue-property-decorator';
import MutationFactory from '@/localCache/mutations/MutationFactory';
import { StudentViewModel } from '@/model/StudentViewModel';
import { BadgeType, getBadgeString } from '@/api/model/BadgeType';
import _ from 'lodash';
import { Student } from '@/api/model/Student';
import moment from 'moment';
import DatePickerDialog from './DatePickerDialog.vue';
import Badge from '@/api/model/Badge';

@Component({
  name: 'BadgeDialog',
  components: {
    DatePickerDialog,
  },
  filters: {
    formatDate(value: unknown) {
      if (!value) {
        return 'N/A';
      }
      if (value instanceof Date) {
        return moment(value).format('DD.MM.YY');
      }
    },
    formatBadgeString(value: unknown) {
      return getBadgeString(value as BadgeType);
    },
  },
})
export default class BadgeDialog extends Vue {
  $refs!: {
    datePickerDialog: DatePickerDialog;
  };
  private anyChanges = false;
  private showDialog = false;
  private student?: StudentViewModel;
  private showDialogPromiseResolve?: (value?: void | PromiseLike<void> | undefined) => void;
  private badges: Badge[] = [];

  public show(student: StudentViewModel): Promise<void> {
    this.student = student;
    this.anyChanges = false;

    this.badges = this.loadBadgesFromStudent(student.studentData);
    this.showDialog = true;
    return new Promise(resolve => {
      this.showDialogPromiseResolve = resolve;
    });
  }

  private loadBadgesFromStudent(student: Student): Badge[] {
    const badgeOrder = [
      BadgeType.Seehorse,
      BadgeType.Bronce,
      BadgeType.Silver,
      BadgeType.Gold,
      BadgeType.SkullOneHour,
      BadgeType.SkullOneHalfHour,
      BadgeType.SkullTwoHour,
    ];

    const editableBadges = _(badgeOrder)
      .map(b => {
        const foundbadge = _(student.badges).find(badge => badge.badgeType == b);
        return {
          badgeType: b,
          date: foundbadge?.date,
          handedOut: foundbadge?.handedOut,
          name: '',
        };
      })
      .value();

    return editableBadges;
  }

  private async saveAndClose(): Promise<void> {
    if (!this.student || !this.anyChanges) {
      this.close();
      return;
    }

    const editableBadges = _(this.badges)
      .filter(b => b.date !== undefined || b.handedOut !== undefined)
      .map(b => {
        return {
          badgeType: b.badgeType,
          date: b.date,
          handedOut: b.handedOut,
          name: '',
        };
      })
      .value();

    await MutationFactory.CreateStudentBadgeMutation(this.student.studentData, editableBadges);
    this.close();
  }

  private async setDate(editableBadge: Badge): Promise<void> {
    return this.$refs.datePickerDialog.show().then(selectedDate => {
      editableBadge.date = selectedDate;
      this.anyChanges = true;
    });
  }
  private async setHandedOut(editableBadge: Badge): Promise<void> {
    return this.$refs.datePickerDialog.show().then(selectedDate => {
      editableBadge.handedOut = selectedDate;
      this.anyChanges = true;
    });
  }

  private close(): void {
    if (this.showDialogPromiseResolve === null || this.showDialogPromiseResolve === undefined)
      return;

    this.showDialog = false;
    this.showDialogPromiseResolve();
  }
}
