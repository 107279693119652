

















































































































import { StudentViewModel } from '../model/StudentViewModel';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'StudentDetailDialog',
})
export default class StudentDetailDialog extends Vue {
  private showDialog = false;
  private student?: StudentViewModel;
  private showDialogPromiseResolve?: (value?: void | PromiseLike<void> | undefined) => void;

  private showPhone(number: string): boolean {
    if (number === '') {
      return false;
    }
    if (number === 'Nicht angegeben') {
      return false;
    }
    if (number === 'N/A') {
      return false;
    }
    return true;
  }

  public show(student: StudentViewModel): Promise<void> {
    this.student = student;
    this.showDialog = true;
    return new Promise(resolve => {
      this.showDialogPromiseResolve = resolve;
    });
  }

  private close(): void {
    if (this.showDialogPromiseResolve === null || this.showDialogPromiseResolve === undefined)
      return;

    this.showDialog = false;
    this.student = undefined;
    this.showDialogPromiseResolve();
  }
}
