import GlobalConfig from '../../globalconfiguration';
import { User } from '../model/User';

class UserService {
  private localStorageName = 'user';

  // Create Auth Header
  public getAuthHeader(): Record<'Authorization', string> | Record<string, never> {
    const storaged = localStorage.getItem(this.localStorageName);
    if (!storaged) return {};

    const user = JSON.parse(storaged);
    if (user && user.token) return { Authorization: 'Bearer ' + user.token };
    else return {};
  }

  public getCurrentUser(): User | null {
    const storaged = localStorage.getItem(this.localStorageName);
    if (!storaged) return null;

    const user = JSON.parse(storaged) as User;
    if (user && user.username && user.password) return user;

    return null;
  }

  // Logout
  public logout(): void {
    localStorage.removeItem(this.localStorageName);
  }

  // Login and create token
  public async login(username: string, password: string): Promise<User> {
    const response = await fetch(GlobalConfig.IssuerUrl + '/api/security/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    const token = await response.text();

    if (!response.ok) {
      if (response.status === 401) {
        this.logout();
        location.reload(true);
      }
      return Promise.reject('Falscher Benutzername oder Passwort');
    }

    const user: User = { password, username, token };
    localStorage.setItem(this.localStorageName, JSON.stringify(user));

    return user;
  }

  // renew a token, user must had been login once
  public async renewToken(): Promise<User> {
    const user = this.getCurrentUser();
    if (!user) throw new Error('Kein Benutzer angemeldet!');

    return this.login(user.username, user.password);
  }
}

export const userService = new UserService();
