class GlobalConfig {
  public readonly IssuerUrl: string = 'https://cashman1972.de';
  public readonly APIUrl: string = 'https://cashman1972.de';

  // Local Dev
  //public readonly IssuerUrl: string = 'http://localhost:5100';
  //public readonly APIUrl: string = 'http://localhost:5000';
  //public readonly APIUrl: string = 'https://api.cashman1972.de';

  public readonly Version: string = '1.12';
}

export default new GlobalConfig();
