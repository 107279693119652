import { Page } from '../model/Page';
import { Student } from '../model/Student';
import { userService } from './UserService';
import authModule from '../../store/modules/auth';
import globalConfig from '../../globalconfiguration';
import { JsonSerializer } from '../../helper/JsonSerializer';
import Course from '../model/Course';
import { PresenceState } from '../model/PresenceState';
import { PaymentMethod } from '../model/PaymentMethod';
import { SubmittedDocumentType } from '../model/SubmittedDocumentType';
import { Trainer } from '../model/Trainer';
import { Interval } from '../model/Interval';
import _ from 'lodash';
import { Duration } from 'moment';
import { Experience } from '../model/Experience';
import Badge from '../model/Badge';

class APIAccess {
  public async studentUpdateBadges(studentId: string, badges: Badge[]): Promise<boolean> {
    const call = (): Promise<Response> => {
      const url = new URL('/api/v1/Student/UpdateBadges', globalConfig.APIUrl);

      const data = {
        studentId: studentId,
        skipCustom: true,

        badges: _(badges).map(b => ({
          date: APIAccess.toDayOnly(b?.date),
          BadgeType: b.badgeType,
          Name: b.name,
          HandedOut: APIAccess.toDayOnly(b?.handedOut),
        })),
      };

      return fetch(url.toString(), {
        method: 'PUT',
        headers: {
          ...userService.getAuthHeader(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    };

    const promise = await this.processApiCall(call);
    return promise.status === 200;
  }

  public async studentAddSubmittedDocuement(
    studentId: string,
    date: Date,
    documentType: SubmittedDocumentType
  ): Promise<boolean> {
    const call = (): Promise<Response> => {
      const url = new URL('/api/v1/Student/AddSubmittedDocument', globalConfig.APIUrl);

      const data = {
        studentId: studentId,
        date: APIAccess.toDayOnly(date),
        documentType: documentType,
      };

      return fetch(url.toString(), {
        method: 'POST',
        headers: {
          ...userService.getAuthHeader(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    };

    const promise = await this.processApiCall(call);
    return promise.status === 200;
  }

  public async trainerAddWorkTime(
    trainerId: string,
    date: Date,
    workTime: Interval<Duration>[]
  ): Promise<boolean> {
    const call = (): Promise<Response> => {
      const url = new URL('/api/v1/Trainer/WorkTime', globalConfig.APIUrl);

      const data = {
        trainerId: trainerId,
        date: APIAccess.toDayOnly(date),
        WorkingTime: _(workTime)
          .map(w => {
            return {
              from: APIAccess.toTime(w.from),
              to: APIAccess.toTime(w.to),
            };
          })
          .value(),
      };

      return fetch(url.toString(), {
        method: 'PUT',
        headers: {
          ...userService.getAuthHeader(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    };

    const promise = await this.processApiCall(call);
    return promise.status === 200;
  }

  public async trainerAddSubmittedDocuement(
    trainerId: string,
    date: Date,
    documentType: SubmittedDocumentType
  ): Promise<boolean> {
    const call = (): Promise<Response> => {
      const url = new URL('/api/v1/Trainer/AddSubmittedDocument', globalConfig.APIUrl);

      const data = {
        trainerId: trainerId,
        date: APIAccess.toDayOnly(date),
        documentType: documentType,
      };

      return fetch(url.toString(), {
        method: 'POST',
        headers: {
          ...userService.getAuthHeader(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    };

    const promise = await this.processApiCall(call);
    return promise.status === 200;
  }

  public async studentUpdatePaidUnit(
    studentId: string,
    unitId: string,
    date: Date,
    method: PaymentMethod
  ): Promise<boolean> {
    const call = (): Promise<Response> => {
      const url = new URL('/api/v1/Student/UpdateUnitPayment', globalConfig.APIUrl);

      const data = {
        studentId: studentId,
        unitId: unitId,
        paidDate: APIAccess.toDayOnly(date),
        paymentMethod: method,
      };

      return fetch(url.toString(), {
        method: 'PUT',
        headers: {
          ...userService.getAuthHeader(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    };

    const promise = await this.processApiCall(call);
    return promise.status === 200;
  }

  public async studentUpdateFloaty(studentId: string, floatyCount: number): Promise<boolean> {
    const call = (): Promise<Response> => {
      const url = new URL('/api/v1/Student/FloatyCount', globalConfig.APIUrl);

      const data = {
        studentId: studentId,
        floatyCount: floatyCount,
      };

      return fetch(url.toString(), {
        method: 'POST',
        headers: {
          ...userService.getAuthHeader(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    };

    const promise = await this.processApiCall(call);
    return promise.status === 200;
  }

  public async studentUpdatePresence(
    studentId: string,
    courseId: string,
    date: Date,
    presence: PresenceState
  ): Promise<boolean> {
    const call = (): Promise<Response> => {
      const url = new URL('/api/v1/Student/Presence', globalConfig.APIUrl);

      const data = {
        studentId: studentId,
        courseId: courseId,
        date: APIAccess.toDayOnly(date),
        presence: presence,
      };

      return fetch(url.toString(), {
        method: 'POST',
        headers: {
          ...userService.getAuthHeader(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    };

    const promise = await this.processApiCall(call);
    return promise.status === 200;
  }

  public async fetchCourseRange(startDate: Date, endDate: Date): Promise<Course[]> {
    const call = (p: number): Promise<Response> => {
      const url = new URL('/api/v1/Course/Range', globalConfig.APIUrl);
      url.searchParams.append('page', `${p}`);
      url.searchParams.append('size', '50');
      url.searchParams.append('startDate', APIAccess.toDayOnly(startDate));
      url.searchParams.append('endDate', APIAccess.toDayOnly(endDate));

      return fetch(url.toString(), {
        method: 'GET',
        headers: userService.getAuthHeader(),
      });
    };

    return this.GetAllFromPageFromCall<Course>(call);
  }

  public async fetchAllStudents(): Promise<Student[]> {
    return this.GetAllFromPage<Student>('/api/v1/Student/Active');
  }

  public async fetchAllTrainers(): Promise<Trainer[]> {
    return this.GetAllFromPage<Trainer>('/api/v1/Trainer/Page');
  }

  private GetAllFromPage<TResult>(apiPath: string): Promise<TResult[]> {
    const call = (p: number): Promise<Response> => {
      const url = new URL(apiPath, globalConfig.APIUrl);
      url.searchParams.append('page', `${p}`);
      url.searchParams.append('size', '50');

      return fetch(url.toString(), {
        method: 'GET',
        headers: userService.getAuthHeader(),
      });
    };

    return this.GetAllFromPageFromCall<TResult>(call);
  }

  private async GetAllFromPageFromCall<TResult>(
    call: (p: number) => Promise<Response>
  ): Promise<TResult[]> {
    let entities: TResult[] = [];

    let page = 0;
    let pageContainer: Page<TResult>;
    do {
      const result = await this.processApiCall(() => call(page++));
      if (!result.ok) throw new Error(await result.text());

      const jsonData = JsonSerializer.Deserialize(await result.text());
      pageContainer = jsonData as Page<TResult>;
      if (pageContainer && pageContainer.entities)
        entities = entities.concat(pageContainer.entities);
    } while (pageContainer.entities.length === pageContainer.size);

    return entities;
  }

  private async processApiCall(call: () => Promise<Response>): Promise<Response> {
    const retry = 2;

    for (let i = 0; i < retry; i++) {
      const promise = await call();

      if (promise.status === 401 && (await authModule.renewToken())) continue;

      return promise;
    }

    throw new Error('Api Call failed');
  }

  private static toDayOnly(date: Date | undefined): string {
    if (date === undefined) return '';
    return `${date.getFullYear()}-${(date.getMonth() + 1).toLocaleString('en-us', {
      minimumIntegerDigits: 2,
    })}-${date.getDate().toLocaleString('en-us', {
      minimumIntegerDigits: 2,
    })}`;
  }

  private static toTime(timespan: Duration): string {
    return timespan.format('hh:mm');
  }
}

export const API = new APIAccess();
