


































































































import { TrainerViewModel } from '../model/TrainerViewModel';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'TrainerDetailDialog',
})
export default class TrainerDetailDialog extends Vue {
  private showDialog = false;
  private trainer?: TrainerViewModel;
  private showDialogPromiseResolve?: (value?: void | PromiseLike<void> | undefined) => void;

  private showPhone(number: string): boolean {
    if (number === '' || number.length <= 1) {
      return false;
    }
    if (number === 'Nicht angegeben') {
      return false;
    }
    if (number === 'N/A') {
      return false;
    }
    return true;
  }

  public show(trainer: TrainerViewModel): Promise<void> {
    this.trainer = trainer;
    this.showDialog = true;
    return new Promise(resolve => {
      this.showDialogPromiseResolve = resolve;
    });
  }

  private close(): void {
    if (this.showDialogPromiseResolve === null || this.showDialogPromiseResolve === undefined)
      return;

    this.showDialog = false;
    this.trainer = undefined;
    this.showDialogPromiseResolve();
  }
}
