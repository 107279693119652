import _ from 'lodash';
import { duration, Duration } from 'moment';
import { Interval } from './Interval';

export class IntervalSet {
  public entities: Interval<Duration>[] = [];

  public clear(): void {
    this.entities = [];
  }

  public add(interval: Interval<Duration>): void {
    if (!_(this.entities).some()) this.entities.push(interval);

    for (let i = 0; i < this.entities.length; i++) {
      if (this.entities[i].doIntersect(interval)) {
        this.entities[i] = this.entities[i].union(interval);
        this.joinRanges();

        return;
      }
    }

    this.entities.push(interval);
  }

  private joinRanges(): void {
    for (let i = 0; i < this.entities.length; i++) {
      for (let j = i + 1; j < this.entities.length; ) {
        if (this.entities[i].doIntersect(this.entities[j])) {
          this.entities[i] = this.entities[i].union(this.entities[j]);
          this.entities.splice(j, 1);
        } else {
          j++;
        }
      }
    }
  }

  public totalTime(): Duration {
    let result = duration(0);

    for (const entry of this.entities) result = result.add(entry.to.subtract(entry.from));

    return result;
  }
}
