import { Duration } from 'moment';
import Unique from './Unique';
import CourseInfo from './CourseInfo';
import FailedSession from './FailedSession';

export enum DayOfWeek {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export function NumberToDayOfWeek(i: number): DayOfWeek {
  switch (i) {
    case 0:
      return DayOfWeek.Saturday;
    case 1:
      return DayOfWeek.Monday;
    case 2:
      return DayOfWeek.Tuesday;
    case 3:
      return DayOfWeek.Wednesday;
    case 4:
      return DayOfWeek.Thursday;
    case 5:
      return DayOfWeek.Friday;
    case 6:
      return DayOfWeek.Saturday;
  }

  throw new Error('Not a week day');
}

export default interface Course extends Unique {
  startDate: Date;
  endDate: Date;
  occurancePerWeek: DayOfWeek[];

  startTime: Duration;
  endTime: Duration;

  courseType: number;

  infos: CourseInfo[];
  failedSessions: FailedSession[];
}
