import db from '../SchoolAccessDatabase';
import { EntityType, IMutation, MutationType } from './IMutation';
export abstract class MutationBase<TEntity> implements IMutation {
  public readonly id: string;
  public readonly entityId: string;
  public readonly entityType: EntityType;

  public date: Date;
  public sortDate: number;
  public readonly entityName: string;
  public readonly message: string;
  public readonly mutationType: MutationType;
  public readonly data: unknown;

  public isSynchronized: boolean;

  public constructor(
    id: string,
    type: MutationType,
    entityId: string,
    entityType: EntityType,
    entityName: string,
    date: number,
    message: string,
    isSynchronized: boolean,
    data: unknown
  ) {
    this.id = id;
    this.entityId = entityId;
    this.entityName = entityName;
    this.entityType = entityType;

    this.message = message;
    this.mutationType = type;
    this.isSynchronized = isSynchronized;
    this.data = data;
    this.date = new Date(date);
    this.sortDate = date;
  }

  public add(): Promise<unknown> {
    try {
      return this.performInTransactionScope(async () => {
        const earlierMutation = await db.getMutationById(this.id);
        await earlierMutation?.remove();

        const entity = await this.getEntity();
        this.performAction(entity);

        await this.updateEntity(entity);
        this.updateAfterSave(entity);

        await db.updateMutation(this);

        return entity;
      });
    } catch {
      throw new Error('Änderung konnte nicht gespeichert werden.');
    }
  }

  public remove(): Promise<unknown> {
    try {
      return this.performInTransactionScope(async () => {
        const entity = await this.getEntity();
        this.performUndo(entity);

        await this.updateEntity(entity);
        this.updateAfterSave(entity);

        await db.deleteMutation(this.id);

        return entity;
      });
    } catch {
      throw new Error('Änderung konnte nicht gespeichert werden.');
    }
  }

  public async synchronize(): Promise<void> {
    try {
      this.isSynchronized = await this.performsynchronize();
      db.updateMutation(this);
    } catch {
      this.isSynchronized = false;
    }
  }

  protected abstract performInTransactionScope(action: () => Promise<TEntity>): Promise<TEntity>;

  protected abstract getEntity(): Promise<TEntity>;
  protected abstract updateEntity(entity: TEntity): Promise<void>;
  protected abstract performsynchronize(): Promise<boolean>;
  protected abstract performAction(entity: TEntity): void;
  protected abstract performUndo(entity: TEntity): void;
  protected abstract updateAfterSave(entity: TEntity): void;
}
