import { Interval } from '@/api/model/Interval';
import { SubmittedDocumentType } from '@/api/model/SubmittedDocumentType';
import { Trainer } from '@/api/model/Trainer';
import { TrainerMutator } from '@/api/model/TrainerMutator';
import { API } from '@/api/service/APIAccess';
import { Duration } from 'moment';
import db from '../../SchoolAccessDatabase';
import { EntityType, MutationType } from '../IMutation';
import { MutationBase } from '../MutationBase';

export abstract class TrainerMutationBase extends MutationBase<Trainer> {
  public constructor(
    id: string,
    type: MutationType,
    entityId: string,
    entityType: EntityType,
    entityName: string,
    date: number,
    message: string,
    isSynchronized: boolean,
    data: unknown
  ) {
    super(id, type, entityId, entityType, entityName, date, message, isSynchronized, data);
  }

  protected performInTransactionScope(action: () => Promise<Trainer>): Promise<Trainer> {
    return db.performInTrainerMutationTransaction(action);
  }

  protected getEntity(): Promise<Trainer> {
    return db.getTrainerById(this.entityId);
  }
  protected updateEntity(entity: Trainer): Promise<void> {
    return db.updateTrainer(entity);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected updateAfterSave(_: Trainer): void {
    return;
  }
}

interface UpdateCovidDocument {
  submitDate: Date;
  documentType: SubmittedDocumentType;
}

interface UpdatePresence {
  sessionDate: Date;
  workTime: Interval<Duration>[];
  originalWorkTime: Interval<Duration>[];
}

export class TrainerCovidDocumentMutation extends TrainerMutationBase {
  public static create(
    id: string,
    studentId: string,
    studentName: string,
    message: string,
    data: unknown
  ): TrainerCovidDocumentMutation {
    return new TrainerCovidDocumentMutation(
      id,
      MutationType.CovidDocument,
      studentId,
      EntityType.Trainer,
      studentName,
      Date.now(),
      message,
      false,
      data
    );
  }
  public static createFromDB(
    id: string,
    studentId: string,
    studentName: string,
    date: number,
    message: string,
    isSynchronized: boolean,
    data: unknown
  ): TrainerCovidDocumentMutation {
    return new TrainerCovidDocumentMutation(
      id,
      MutationType.CovidDocument,
      studentId,
      EntityType.Trainer,
      studentName,
      date,
      message,
      isSynchronized,
      data
    );
  }

  protected performAction(trainer: Trainer): void {
    const data = this.data as UpdateCovidDocument;
    TrainerMutator.addSubmittedDocument(trainer, data.submitDate, data.documentType);
  }
  protected performUndo(trainer: Trainer): void {
    const data = this.data as UpdateCovidDocument;
    TrainerMutator.removeSubmittedDocument(trainer, data.submitDate, data.documentType);
  }

  protected performsynchronize(): Promise<boolean> {
    const data = this.data as UpdateCovidDocument;

    return API.trainerAddSubmittedDocuement(this.entityId, data.submitDate, data.documentType);
  }
}

export class TrainerPresenceMutation extends TrainerMutationBase {
  public static create(
    id: string,
    studentId: string,
    studentName: string,
    message: string,
    data: unknown
  ): TrainerPresenceMutation {
    return new TrainerPresenceMutation(
      id,
      MutationType.Present,
      studentId,
      EntityType.Trainer,
      studentName,
      Date.now(),
      message,
      false,
      data
    );
  }
  public static createFromDB(
    id: string,
    studentId: string,
    studentName: string,
    date: number,
    message: string,
    isSynchronized: boolean,
    data: unknown
  ): TrainerPresenceMutation {
    return new TrainerPresenceMutation(
      id,
      MutationType.Present,
      studentId,
      EntityType.Trainer,
      studentName,
      date,
      message,
      isSynchronized,
      data
    );
  }

  protected performAction(trainer: Trainer): void {
    const data = this.data as UpdatePresence;
    TrainerMutator.setWorkTime(trainer, data.sessionDate, data.workTime);
  }
  protected performUndo(trainer: Trainer): void {
    const data = this.data as UpdatePresence;
    TrainerMutator.setWorkTime(trainer, data.sessionDate, data.originalWorkTime);
  }

  protected async performsynchronize(): Promise<boolean> {
    const data = this.data as UpdatePresence;
    return API.trainerAddWorkTime(this.entityId, data.sessionDate, data.workTime);
  }
}
