import Course from '@/api/model/Course';
import CourseInfo from '@/api/model/CourseInfo';
import { Interval } from '@/api/model/Interval';
import { PresenceState } from '@/api/model/PresenceState';
import { Student } from '@/api/model/Student';
import { Trainer } from '@/api/model/Trainer';
import { API } from '@/api/service/APIAccess';
import { DateHelper } from '@/helper/DateHelper';
import { PresenceStudentViewModel } from '@/model/PresenceStudentViewModel';
import { PresenceTrainerViewModel } from '@/model/PresenceTrainerViewModel';
import { StudentViewModel } from '@/model/StudentViewModel';
import { TrainerViewModel } from '@/model/TrainerViewModel';
import promiseDispatcher from '@/store/modules/promiseDispatcher';
import _ from 'lodash';
import moment, { Duration } from 'moment';
import { EntityType, IMutation } from './mutations/IMutation';
import db from './SchoolAccessDatabase';

const SyncDateName = 'SyncDate';
const CacheRangeFromName = 'CacheRangeFrom';
const CacheRangeToName = 'CacheRangeTo';

class SchoolCache {
  public syncDate: Date | undefined;
  public from: Date | undefined;
  public to: Date | undefined;
  public isValid: boolean;
  public mutationCount: number;

  private allStudents: { [id: string]: StudentViewModel } = {};
  private allTrainers: { [id: string]: TrainerViewModel } = {};

  /**
   * Filter, if student participate the course at that date
   */
  constructor() {
    this.syncDate = SchoolCache.getDateInStorage(SyncDateName);
    this.from = SchoolCache.getDateInStorage(CacheRangeFromName);
    this.to = SchoolCache.getDateInStorage(CacheRangeToName);
    this.mutationCount = 0;
    this.isValid = false;

    if (this.syncDate !== undefined) {
      this.isValid = true;
      db.getMutationCount().then(n => (this.mutationCount = n));
    } else {
      this.setInvalid();
    }
  }

  public async wipeCache(): Promise<void> {
    await db.delete();
    this.setInvalid();
  }

  public async syncCache(): Promise<void> {
    // Write Mutations back
    const allMutations = await db.getActiveMutations();

    try {
      promiseDispatcher.increase();

      for (const m of allMutations) {
        await m.synchronize();
      }

      // Reload Database cache
      const [from, to] = await SchoolCache.createCache();
      this.setValid(from, to);
    } finally {
      promiseDispatcher.decrease();
    }
  }

  // Integrator
  public clearSyncedMutations(): Promise<void> {
    return db.clearSyncedMutations();
  }

  // Integrator
  public async getAllStudents(): Promise<StudentViewModel[]> {
    this.ensureValid();
    this.allStudents = await SchoolCache.initializeStudentsIfNeeded(this.allStudents);
    return Object.values(this.allStudents);
  }

  // Integrator
  public async getAllTrainers(): Promise<TrainerViewModel[]> {
    this.ensureValid();
    this.allTrainers = await SchoolCache.initializeTrainersIfNeeded(this.allTrainers);
    return Object.values(this.allTrainers);
  }

  // Integrator
  public getAllMutations(): Promise<IMutation[]> {
    return SchoolCache.loadMutations();
  }

  public getMutationById(id: string): Promise<IMutation | undefined> {
    return db.getMutationById(id);
  }

  // Integrator
  public async getStudent(id: string): Promise<StudentViewModel> {
    this.ensureValid();
    this.allStudents = await SchoolCache.initializeStudentsIfNeeded(this.allStudents);

    return this.allStudents[id];
  }

  // Integrator
  public async getTrainer(id: string): Promise<TrainerViewModel> {
    this.ensureValid();
    this.allTrainers = await SchoolCache.initializeTrainersIfNeeded(this.allTrainers);

    return this.allTrainers[id];
  }

  // Integrator
  public async getPresenceStudents(date: Date): Promise<PresenceStudentViewModel[]> {
    const day = DateHelper.onlyDate(date);
    const dayTime = day.getTime();

    this.ensureValid();
    this.ensureInRange(day);
    this.allStudents = await SchoolCache.initializeStudentsIfNeeded(this.allStudents);

    try {
      promiseDispatcher.increase();
      const cachedCourses: { [id: string]: { c: Course; i: CourseInfo } } = {};

      const result = [];
      for (const student of Object.values(this.allStudents)) {
        const courseId = SchoolCache.studentParticipateCourse(dayTime, student);
        if (courseId === undefined) continue;

        const { c, i } = await SchoolCache.getCourse(courseId, dayTime, cachedCourses);
        result.push(new PresenceStudentViewModel(student, c, i, day));
      }

      return result;
    } finally {
      promiseDispatcher.decrease();
    }
  }

  // Integrator
  public async getPresenceTainers(date: Date): Promise<PresenceTrainerViewModel[]> {
    const day = DateHelper.onlyDate(date);

    return _(await this.getAllTrainers())
      .filter(t => t.isActive)
      .map(t => new PresenceTrainerViewModel(t, day))
      .value();
  }

  // Integrator
  public async getSessionTimes(date: Date): Promise<Interval<Duration>[]> {
    const day = DateHelper.onlyDate(date);

    const sessionTimes = _(await db.getAllSessionsOfDay(day))
      .map(c => new Interval<Duration>(c.startTime, c.endTime))
      .value();

    return _(sessionTimes)
      .uniqWith(
        (a, b) =>
          a.from.asMilliseconds() === b.from.asMilliseconds() &&
          a.to.asMilliseconds() === b.to.asMilliseconds()
      )
      .orderBy(a => a.from.asMinutes())
      .value();
  }

  // Integrator
  public async addMutation(m: IMutation): Promise<void> {
    const entity = await m.add();

    switch (m.entityType) {
      case EntityType.Student:
        {
          const student = entity as Student;
          const studentVM = await this.getStudent(student.id);
          studentVM.update(student);
        }
        break;

      case EntityType.Trainer:
        {
          const trainer = entity as Trainer;
          const trainerVM = await this.getTrainer(trainer.id);
          trainerVM.update(trainer);
        }
        break;
    }
  }

  // Integrator
  public async removeMutation(m: IMutation): Promise<void> {
    const entity = await m.remove();

    switch (m.entityType) {
      case EntityType.Student:
        {
          const student = entity as Student;
          const studentVM = await this.getStudent(student.id);
          studentVM.update(student);
        }
        break;

      case EntityType.Trainer:
        {
          const trainer = entity as Trainer;
          const trainerVM = await this.getTrainer(trainer.id);
          trainerVM.update(trainer);
        }
        break;
    }
  }

  // Integrator
  public async removeMutationById(id: string): Promise<void> {
    const mutation = await db.getMutationById(id);
    if (mutation === undefined) return;
    await this.removeMutation(mutation);
  }

  // Integrator
  private setValid(from: Date, to: Date): void {
    this.syncDate = new Date();
    this.from = from;
    this.to = to;
    this.isValid = true;

    SchoolCache.setDateInStorage(SyncDateName, this.syncDate);
    SchoolCache.setDateInStorage(CacheRangeFromName, from);
    SchoolCache.setDateInStorage(CacheRangeToName, to);
  }

  // Integrator
  private setInvalid(): void {
    this.syncDate = undefined;
    this.from = undefined;
    this.to = undefined;
    this.isValid = false;
    this.allStudents = {};

    localStorage.removeItem(SyncDateName);
    localStorage.removeItem(CacheRangeFromName);
    localStorage.removeItem(CacheRangeToName);
  }

  // Integrator
  private ensureValid(): void {
    return SchoolCache.ensureValid(this.isValid);
  }
  // Integrator
  private ensureInRange(date: Date): void {
    return SchoolCache.ensureInRange(this.from, this.to, date);
  }

  // Operator
  private static async createCache(): Promise<[Date, Date]> {
    const students = _(await API.fetchAllStudents())
      .filter(s => s.firstname !== '' || !s.isDeleted)
      .value();

    const trainers = _(await API.fetchAllTrainers())
      .filter(s => s.contact.firstname !== '' || !s.isDeleted)
      .value();

    const from = DateHelper.addDays(new Date(), -7 * 5);
    const to = DateHelper.addDays(new Date(), 7 * 5);

    const courses = await API.fetchCourseRange(from, to);

    await db.initialize(students, courses, trainers);
    return [from, to];
  }

  // Operator
  private static async getCourse(
    id: string,
    dayTime: number,
    cachedCourses: { [id: string]: { c: Course; i: CourseInfo } }
  ): Promise<{ c: Course; i: CourseInfo }> {
    if (!(id in cachedCourses)) {
      const c = await db.getCourseById(id);
      if (c === undefined) throw new Error('Kurs ist nicht geladen. Bitte neu Synchronisieren!');

      const info = _(c.infos).find(
        i => i.validFrom.getTime() <= dayTime && i.validTo.getTime() >= dayTime
      );
      cachedCourses[id] = { c, i: info as CourseInfo };
    }

    return cachedCourses[id];
  }

  // Operator
  private static studentParticipateCourse(
    date: number,
    student: StudentViewModel
  ): string | undefined {
    if (student.isDeleted) return undefined;

    const takeSession = _(student.takeSessions).find(ts => {
      const day = DateHelper.onlyDateTicks(ts.dateTime);
      return day === date;
    });
    if (takeSession === undefined) return undefined;

    if (!student.isActive && takeSession.presence === PresenceState.Unknown) return undefined;

    return takeSession.courseId;
  }

  // Operator
  private static async loadMutations(): Promise<IMutation[]> {
    try {
      promiseDispatcher.increase();
      return await db.getAllMutations();
    } finally {
      promiseDispatcher.decrease();
    }
  }

  // Operator
  private static async initializeStudentsIfNeeded(allStudents: {
    [id: string]: StudentViewModel;
  }): Promise<{ [id: string]: StudentViewModel }> {
    if (Object.keys(allStudents).length > 0) return allStudents;

    try {
      promiseDispatcher.increase();
      return _.keyBy(
        (await db.getAllStudents()).map(s => new StudentViewModel(s)),
        s => s.studentData.id
      );
    } finally {
      promiseDispatcher.decrease();
    }
  }

  private static async initializeTrainersIfNeeded(allTrainers: {
    [id: string]: TrainerViewModel;
  }): Promise<{ [id: string]: TrainerViewModel }> {
    if (Object.keys(allTrainers).length > 0) return allTrainers;

    try {
      promiseDispatcher.increase();
      return _.keyBy(
        (await db.getAllTrainers()).map(t => new TrainerViewModel(t)),
        t => t.trainerData.id
      );
    } finally {
      promiseDispatcher.decrease();
    }
  }

  // Operator
  private static ensureValid(isValid: boolean): void {
    if (!isValid) throw new Error('Der Cache ist ungültig. Bitte neu synchronisieren!');
  }

  // Operator
  private static ensureInRange(from: Date | undefined, to: Date | undefined, date: Date): void {
    if (from === undefined || to === undefined)
      throw new Error('Der Cache ist ungültig. Bitte neu synchronisieren!');

    const day = DateHelper.onlyDate(date);
    if (DateHelper.onlyDate(from as Date) > day || DateHelper.onlyDate(to as Date) < day)
      throw new Error(`Der Tag ${moment(day).format('DD.MM.YYYY')}`);
  }

  // Operator
  private static setDateInStorage(entryName: string, date: Date): void {
    localStorage.setItem(entryName, JSON.stringify({ time: date.getTime() }));
  }

  // Operator
  private static getDateInStorage(entryName: string): Date | undefined {
    try {
      const entry = localStorage.getItem(entryName);
      if (entry) return new Date(JSON.parse(entry).time);
    } catch {
      // nothing or broken
    }

    return undefined;
  }
}

const schoolCache = new SchoolCache();
export default schoolCache;
