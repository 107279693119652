












































import { StudentViewModel } from '../model/StudentViewModel';
import { Component, Vue } from 'vue-property-decorator';
import { TakeSessionModel } from '../model/TakeSessionModel';
import moment from 'moment';
import _ from 'lodash';

@Component({
  name: 'StudentPresenceDialog',
  filters: {
    formatDate(value: unknown) {
      if (value === undefined) {
        return '';
      }
      if (value instanceof Date) {
        return moment(value).format('DD.MM.YYYY');
      }
    },
  },
})
export default class StudentPresenceDialog extends Vue {
  private showDialog = false;
  private student?: StudentViewModel;
  private data?: TakeSessionModel[];
  private showDialogPromiseResolve?: (value?: void | PromiseLike<void> | undefined) => void;
  private sortBy = 'sortableDate';
  private sortDesc = true;

  private get headers() {
    return [
      { text: 'Kurs Nr.', align: 'start', value: 'unitNumber' },
      { text: 'Datum', value: 'sortableDate' },
      { text: 'Anwesend', value: 'presence' },
    ];
  }

  public show(student: StudentViewModel): Promise<void> {
    this.student = student;

    this.data = _(student.takeSessions)
      .map(ts => new TakeSessionModel(ts))
      .value();
    this.showDialog = true;

    return new Promise(resolve => {
      this.showDialogPromiseResolve = resolve;
    });
  }

  private close(): void {
    if (this.showDialogPromiseResolve === null || this.showDialogPromiseResolve === undefined)
      return;

    this.showDialog = false;
    this.student = undefined;
    this.data = undefined;
    this.showDialogPromiseResolve();
  }
}
