export class Interval<TValue> {
  public from: TValue;
  public to: TValue;

  constructor(from: TValue, to: TValue) {
    this.from = from;
    this.to = to;
  }

  public include(data: TValue | Interval<TValue>): boolean {
    const value = data as TValue;
    if (value !== undefined) return this.ge(value, this.from) && this.le(value, this.to);

    const interval = data as Interval<TValue>;
    if (interval !== undefined)
      return this.ge(interval.from, this.from) && this.le(interval.to, this.to);

    throw new Error('Type not supported');
  }

  public doIntersect(interval: Interval<TValue>): boolean {
    return this.le(interval.from, this.to) && this.ge(interval.to, this.from);
  }

  public union(interval: Interval<TValue>): Interval<TValue> {
    if (!this.doIntersect(interval)) throw new Error('Intervals do not intersect');

    return new Interval<TValue>(this.min(interval.from, this.from), this.max(interval.to, this.to));
  }

  public Intersect(interval: Interval<TValue>): Interval<TValue> {
    if (!this.doIntersect(interval)) throw new Error('Intervals do not intersect');

    return new Interval<TValue>(this.max(interval.from, this.from), this.min(interval.to, this.to));
  }

  private max(v1: TValue, v2: TValue): TValue {
    return this.ge(v1, v2) ? v1 : v2;
  }
  private min(v1: TValue, v2: TValue): TValue {
    return this.le(v1, v2) ? v1 : v2;
  }

  private ge(v1: TValue, v2: TValue): boolean {
    return v1 >= v2;
  }
  private g(v1: TValue, v2: TValue): boolean {
    return v1 > v2;
  }
  private le(v1: TValue, v2: TValue): boolean {
    return v1 <= v2;
  }
  private l(v1: TValue, v2: TValue): boolean {
    return v1 < v2;
  }
}
