






















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'DatePickerDialog',
})
export default class DatePickerDialog extends Vue {
  @Prop() public title = '';
  @Prop() public showNA = false;
  private selectedDate: string = new Date().toISOString().substr(0, 10);

  private showDialog = false;
  private showDialogPromiseResolve?: (value?: Date | PromiseLike<Date> | undefined) => void;
  private showDialogPromiseReject?: (value?: Date | PromiseLike<Date> | undefined) => void;

  public show(): Promise<Date | undefined> {
    this.showDialog = true;
    return new Promise<Date | undefined>((resolve, reject) => {
      this.showDialogPromiseResolve = resolve;
      this.showDialogPromiseReject = reject;
    });
  }

  private canceled(): void {
    this.showDialog = false;

    if (!this.showDialogPromiseReject) {
      return;
    }

    this.showDialogPromiseReject(undefined);
    this.showDialogPromiseReject = undefined;
  }

  private today(): void {
    this.selectedDate = new Date().toISOString().substr(0, 10);
    this.closed();
  }

  private notAvailable(): void {
    this.showDialog = false;
    if (this.showDialogPromiseResolve === null || this.showDialogPromiseResolve === undefined) {
      return;
    }

    this.showDialogPromiseResolve(undefined);
    this.showDialogPromiseResolve = undefined;
  }

  private closed(): void {
    this.showDialog = false;
    if (this.showDialogPromiseResolve === null || this.showDialogPromiseResolve === undefined) {
      return;
    }

    this.showDialogPromiseResolve(new Date(this.selectedDate));
    this.showDialogPromiseResolve = undefined;
  }
}
