import Vue from 'vue';
import Vuetify from 'vuetify';
import Vue2Filters from 'vue2-filters';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

const opts = {};

export default new Vuetify(opts);

Vue.use(Vue2Filters);
