import { Covid19DocumentManager } from './Covid19DocumentManager';

export class PresenceCovid19DocumentManager {
  private manager: Covid19DocumentManager;

  public submittedCovid19DocumentValid = false;
  public lastSubmittedCovid19Document: Date | undefined;
  public hasSubmittedCovidDocuments = false;

  public constructor(manager: Covid19DocumentManager, date: Date) {
    this.manager = manager;

    this.update(date);
  }

  public update(date: Date): void {
    this.submittedCovid19DocumentValid = this.manager.isCovidDocmentAvaliable(date);
    this.lastSubmittedCovid19Document = this.manager.covidDocmentLastSubmitted(date);
    this.hasSubmittedCovidDocuments = date === this.lastSubmittedCovid19Document;
  }
}
