













import { Component, Vue } from 'vue-property-decorator';
import promiseDispatcher from '../store/modules/promiseDispatcher';

@Component({
  name: 'LoadingDialog',
})
export default class LoadingDialog extends Vue {
  private get showDialog(): boolean {
    return promiseDispatcher.foregroundTaskCount > 0;
  }
}
