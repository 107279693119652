




































import { Component, Vue } from 'vue-property-decorator';
import LoadingDialog from './components/LoadingDialog.vue';
import StatusNotification from './components/StatusNotification.vue';
import globalConfig from './globalconfiguration';

@Component({
  name: 'App',
  components: {
    LoadingDialog,
    StatusNotification,
  },
})
export default class App extends Vue {
  private version: string = globalConfig.Version;
  private showDrawer = false;
  private menuItems = [
    { title: 'Kurse', link: '/', icon: 'mdi-swim' },
    { title: 'Trainer', link: '/trainers', icon: 'mdi-alien' },
    {
      title: 'Alle Schüler',
      link: '/allstudents',
      icon: 'mdi-account-multiple',
    },
    { title: 'Sync', link: '/sync', icon: 'mdi-sync' },
  ];
}
