import { PresenceState } from '../api/model/PresenceState';
import Course from '../api/model/Course';
import _ from 'lodash';
import 'moment-duration-format';
import { StudentViewModel } from './StudentViewModel';
import { PresenceCovid19DocumentManager } from './PresenceCovid19DocumentManager';
import CourseInfo from '../api/model/CourseInfo';
import MutationFactory from '@/localCache/mutations/MutationFactory';
import SchoolCache from '@/localCache/SchoolCache';
import { globalEvent } from '@billjs/event-emitter';
import { DateHelper } from '@/helper/DateHelper';

export class PresenceStudentViewModel {
  public student: StudentViewModel;
  public course: Course;
  public courseStartTime: string;
  public courseInfo: CourseInfo;
  public date: Date;
  public presence: PresenceState;

  public covidDocuments: PresenceCovid19DocumentManager;

  private eventId: string;

  constructor(student: StudentViewModel, course: Course, courseInfo: CourseInfo, date: Date) {
    this.student = student;
    this.course = course;
    this.courseStartTime = course.startTime.format('hh:mm');
    this.courseInfo = courseInfo;
    this.date = date;
    this.presence = PresenceState.Unknown;
    this.eventId = `student-${student.studentData.id}`;

    this.covidDocuments = new PresenceCovid19DocumentManager(
      this.student.covidDocuments,
      this.date
    );

    this.update();

    globalEvent.on(this.eventId, () => {
      this.update();
    });
  }

  private update(): void {
    const session = _(this.student.takeSessions).find(
      ts =>
        ts.courseId === this.course.id &&
        DateHelper.onlyDateTicks(ts.dateTime) === this.date.getTime()
    );

    this.presence = session?.presence ?? PresenceState.Unknown;
    this.covidDocuments.update(this.date);
  }

  public updatePresence(newPresence: PresenceState): Promise<void> {
    const mutation = MutationFactory.CreateStudentPresenceMutation(
      this.student.studentData,
      this.date,
      this.course,
      newPresence
    );

    this.presence = newPresence;
    return SchoolCache.addMutation(mutation);
  }

  public async toggleSubmittedCovidDocument(): Promise<void> {
    const existingMutation = await SchoolCache.getMutationById(
      MutationFactory.getStudentCovidSubmittedMutationId(this.student.studentData, this.date)
    );

    if (existingMutation !== undefined) {
      // Remove current mutation
      await SchoolCache.removeMutation(existingMutation);
    } else {
      // No mutation and valid, means, no change here!
      if (this.covidDocuments.submittedCovid19DocumentValid) return;

      const mutation = MutationFactory.CreateStudentCovidDocumentMutation(
        this.student.studentData,
        this.date
      );

      await SchoolCache.addMutation(mutation);
    }

    this.covidDocuments.update(this.date);
  }
}
