import IContact from '../api/model/Contact';
import SubmittedDocument from '../api/model/SubmittedDocument';
import { Covid19DocumentManager } from './Covid19DocumentManager';
import _ from 'lodash';
import { Trainer } from '@/api/model/Trainer';
import { TrainerCost } from '@/api/model/TrainerCost';
import { HoldSession } from '@/api/model/HoldSession';
import { globalEvent } from '@billjs/event-emitter';

export class TrainerViewModel {
  public trainerData: Trainer;

  public get name(): string {
    return this.trainerData.contact.firstname + ' ' + this.trainerData.contact.lastname;
  }
  public get contact(): IContact {
    return this.trainerData.contact;
  }

  public get isDeleted(): boolean {
    return this.trainerData.isDeleted;
  }
  public get isActive(): boolean {
    return this.trainerData.isActive;
  }

  public get costs(): TrainerCost[] {
    return this.trainerData.costs;
  }
  public get holdSession(): HoldSession[] {
    return this.trainerData.holdSessions;
  }
  public get submittedDocuments(): SubmittedDocument[] {
    return this.trainerData.submittedDocuments;
  }

  public covidDocuments: Covid19DocumentManager = new Covid19DocumentManager();

  constructor(trainerData: Trainer) {
    this.trainerData = trainerData;
    this.update(trainerData);
  }

  public update(trainerData: Trainer): void {
    this.trainerData = trainerData;
    this.covidDocuments.Update(this.trainerData.submittedDocuments);

    const eventId = `trainer-${trainerData.id}`;
    globalEvent.fire(eventId, '');
  }
}
