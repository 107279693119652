import { DateHelper } from '@/helper/DateHelper';
import _ from 'lodash';
import { Experience } from './Experience';
import { PaymentMethod } from './PaymentMethod';
import { PresenceState } from './PresenceState';
import { Student } from './Student';
import { SubmittedDocumentType } from './SubmittedDocumentType';

export class StudentMutator {
  public static getFloaty(student: Student): number {
    if (student.floatCount !== undefined && student.floatCount !== null) {
      return student.floatCount;
    } else {
      throw new Error(
        `Der Schüler ${student.firstname} ${student.lastname} ist kein Kind und hat keine Schwimmhilfen.`
      );
    }
  }

  public static setFloaty(student: Student, floatyCount: number): void {
    if (student.floatCount !== undefined && student.floatCount !== null) {
      student.floatCount = floatyCount;
    } else {
      throw new Error(
        `Der Schüler ${student.firstname} ${student.lastname} ist kein Kind und hat keine Schwimmhilfen.`
      );
    }
  }

  public static getPresence(student: Student, courseId: string, dateTime: number): PresenceState {
    const session = _(student.takeSessions).find(
      ts => ts.courseId === courseId && DateHelper.onlyDateTicks(ts.dateTime) === dateTime
    );

    if (session === undefined)
      throw new Error(
        `Tag der Anwesenheit konnte nicht gefunden werden.\nSchüler ${student.firstname} ${student.lastname}\n CourseId ${courseId}`
      );

    return session.presence;
  }

  public static setPresence(
    student: Student,
    courseId: string,
    dateTime: number,
    presence: PresenceState
  ): void {
    const session = _(student.takeSessions).find(
      ts => ts.courseId === courseId && DateHelper.onlyDateTicks(ts.dateTime) === dateTime
    );

    if (session === undefined)
      throw new Error(
        `Tag der Anwesenheit konnte nicht gefunden werden.\nSchüler ${student.firstname} ${student.lastname}\n CourseId ${courseId} Presence ${presence}`
      );

    session.presence = presence;
  }

  public static removeSubmittedDocument(
    student: Student,
    submitDate: Date,
    type: SubmittedDocumentType
  ): void {
    student.submittedDocuments = _(student.submittedDocuments)
      .filter(s => s.date.getTime() !== submitDate.getTime() || s.documentType !== type)
      .value();
  }

  public static addSubmittedDocument(
    student: Student,
    submitDate: Date,
    type: SubmittedDocumentType
  ): void {
    student.submittedDocuments.push({
      date: submitDate,
      documentType: type,
    });
  }

  public static payUnits(student: Student, unitIds: string[], method: PaymentMethod): void {
    const openUnits = _(student.units)
      .filter(u => unitIds.includes(u.id))
      .value();

    for (const unit of openUnits) unit.paidMethod = method;
  }

  public static getAmountOfUnits(student: Student, unitIds: string[]): number {
    return _(student.units)
      .filter(u => unitIds.includes(u.id))
      .sumBy(u => u.amount);
  }

  public static getUnpaidUnits(student: Student): {
    unitIds: string[];
    amount: number;
  } {
    const openUnits = _(student.units)
      .filter(u => u.paidMethod === PaymentMethod.None)
      .value();

    return {
      unitIds: _.map(openUnits, u => u.id),
      amount: openUnits.length === 0 ? 0 : _.sumBy(openUnits, u => u.amount),
    };
  }
}
