import { Student } from '../api/model/Student';
import { Trainer } from '@/api/model/Trainer';
import { JsonSerializer } from '../helper/JsonSerializer';
import Dexie from 'dexie';
import _ from 'lodash';
import Course, { DayOfWeek, NumberToDayOfWeek } from '@/api/model/Course';
import { EntityType, IMutation, MutationType } from './mutations/IMutation';
import {
  StudentBadgeMutation,
  StudentCovidDocumentMutation,
  StudentFloatyMutation,
  StudentPaymentMutation,
  StudentPresenceMutation,
} from './mutations/student/StudentMutation';
import {
  TrainerCovidDocumentMutation,
  TrainerPresenceMutation,
} from './mutations/trainer/TrainerMutation';

interface DataBlob {
  id: string;
  blob: string;
}

interface CourseBlob {
  id: string;
  startDate: number;
  endDate: number;
  blob: string;
}

interface MutationBlob {
  id: string;
  entityId: string;
  entityType: number;
  mutationType: MutationType;
  entityName: string;
  date: number;
  message: string;
  isSynchronized: number;

  blob: string;
}

class SchoolAccessDatabase extends Dexie {
  private students: Dexie.Table<DataBlob, string>;
  private trainers: Dexie.Table<DataBlob, string>;
  private courses: Dexie.Table<CourseBlob, string>;
  private mutations: Dexie.Table<MutationBlob, string>;

  constructor() {
    super('SchoolAccess');
    this.version(2)
      .stores({
        students: 'id, blob',
        trainers: 'id, blob',
        courses: 'id, startDate, endDate, blob',
        mutations:
          'id, entityId, entityType, entityName, mutationType, message, isSynchronized, blob',
      })
      .upgrade(trans => {
        return trans
          .table('courses')
          .toCollection()
          .modify(course => {
            const c = JsonSerializer.Deserialize(course.blob) as Course;

            course.startDate = c.startDate.getTime();
            course.endDate = c.startDate.getTime();
          });
      });

    this.students = this.table('students');
    this.trainers = this.table('trainers');
    this.courses = this.table('courses');
    this.mutations = this.table('mutations');
  }

  public async initialize(
    students: Student[],
    courses: Course[],
    trainers: Trainer[]
  ): Promise<void> {
    this.students.clear();
    this.trainers.clear();
    this.courses.clear();

    // student
    const sudentsToWrite = _(students)
      .filter(s => s.firstname !== '' || !s.isDeleted)
      .map(s => ({ id: s.id, blob: JSON.stringify(s) }))
      .value();

    await this.students.bulkAdd(sudentsToWrite);

    // student
    const trainersToWrite = _(trainers)
      .filter(t => t.contact.firstname !== '' || !t.isDeleted)
      .map(t => ({ id: t.id, blob: JSON.stringify(t) }))
      .value();

    await this.trainers.bulkAdd(trainersToWrite);

    // course
    const coursesToWrite = _(courses)
      .map(c => ({
        id: c.id,
        startDate: c.startDate.getTime(),
        endDate: c.endDate.getTime(),
        blob: JSON.stringify(c),
      }))
      .value();

    await this.courses.bulkAdd(coursesToWrite);
  }

  public async getAllSessionsOfDay(day: Date): Promise<Course[]> {
    const courses = await this.courses
      .where('startDate')
      .belowOrEqual(day.getTime())
      .filter(c => c.endDate >= day.getTime())
      .toArray(c => c.map(s => JsonSerializer.Deserialize(s.blob) as Course));

    const dayOfWeek = NumberToDayOfWeek(day.getDay());
    return _(courses)
      .filter(c => c.occurancePerWeek.includes(dayOfWeek))
      .value();
  }

  public getAllStudents(): Promise<Student[]> {
    return this.students.toArray(students =>
      students.map(s => JsonSerializer.Deserialize(s.blob) as Student)
    );
  }

  public getAllTrainers(): Promise<Trainer[]> {
    return this.trainers.toArray(trainers =>
      trainers.map(s => JsonSerializer.Deserialize(s.blob) as Trainer)
    );
  }

  public getActiveMutations(): Promise<IMutation[]> {
    return this.mutations
      .where('isSynchronized')
      .equals(0)
      .toArray(mutations => mutations.map(m => this.createMutationObject(m as MutationBlob)));
  }

  public getAllMutations(): Promise<IMutation[]> {
    return this.mutations.toArray(mutations =>
      mutations.map(m => this.createMutationObject(m as MutationBlob))
    );
  }

  private createMutationObject(entry: MutationBlob): IMutation {
    switch (entry.entityType) {
      case EntityType.Student:
        switch (entry.mutationType) {
          case MutationType.Present:
            return this.mutationActivator(StudentPresenceMutation, entry);
          case MutationType.FloatyCount:
            return this.mutationActivator(StudentFloatyMutation, entry);
          case MutationType.Payment:
            return this.mutationActivator(StudentPaymentMutation, entry);
          case MutationType.CovidDocument:
            return this.mutationActivator(StudentCovidDocumentMutation, entry);
          case MutationType.Badge:
            return this.mutationActivator(StudentBadgeMutation, entry);

          default:
            throw new Error('Datenbank Mutation nicht bekannt!');
        }

      case EntityType.Trainer:
        switch (entry.mutationType) {
          case MutationType.Present:
            return this.mutationActivator(TrainerPresenceMutation, entry);
          case MutationType.CovidDocument:
            return this.mutationActivator(TrainerCovidDocumentMutation, entry);

          default:
            throw new Error('Datenbank Mutation nicht bekannt!');
        }
        break;

      default:
        throw new Error('Datenbank Mutation nicht bekannt!');
    }
  }

  private mutationActivator<T extends IMutation>(
    mutationType: {
      createFromDB(
        id: string,
        entityId: string,
        entityName: string,
        date: number,
        message: string,
        isSynchronized: boolean,
        data: unknown
      ): T;
    },
    entry: MutationBlob
  ): T {
    const blobData = JsonSerializer.Deserialize(entry.blob);
    const m = mutationType.createFromDB(
      entry.id,
      entry.entityId,
      entry.entityName,
      entry.date,
      entry.message,
      entry.isSynchronized === 1,
      blobData
    );

    return m;
  }

  public async updateTrainer(trainer: Trainer): Promise<void> {
    const entry = { blob: JSON.stringify(trainer) };
    await this.trainers.update(trainer.id, entry);
  }

  public async getTrainerById(id: string): Promise<Trainer> {
    const entry = await this.trainers.get(id);
    if (entry === undefined) throw new Error('Trainer nicht gefunden');

    return JsonSerializer.Deserialize(entry.blob) as Trainer;
  }

  public async updateStudent(student: Student): Promise<void> {
    const entry = { blob: JSON.stringify(student) };
    await this.students.update(student.id, entry);
  }

  public async getStudentById(id: string): Promise<Student> {
    const entry = await this.students.get(id);
    if (entry === undefined) throw new Error('Schüler nicht gefunden');

    return JsonSerializer.Deserialize(entry.blob) as Student;
  }

  public async getCourseById(id: string): Promise<Course | undefined> {
    const entry = await this.courses.get(id);
    if (entry === undefined) return undefined;

    return JsonSerializer.Deserialize(entry.blob) as Course;
  }

  public getMutationCount(): Promise<number> {
    return this.mutations.count();
  }

  public async getMutationById(id: string): Promise<IMutation | undefined> {
    const entry = await this.mutations.get(id);
    if (entry === undefined) return undefined;

    return this.createMutationObject(entry);
  }

  public async updateMutation(m: IMutation): Promise<void> {
    await this.mutations.put({
      id: m.id,
      entityId: m.entityId,
      entityType: m.entityType,
      mutationType: m.mutationType,
      entityName: m.entityName,
      date: m.sortDate,
      message: m.message,
      isSynchronized: m.isSynchronized ? 1 : 0,

      blob: JSON.stringify(m.data),
    });
  }

  public async clearSyncedMutations(): Promise<void> {
    await this.mutations.where('isSynchronized').equals(1).delete();
  }

  public async deleteMutation(id: string): Promise<void> {
    await this.mutations.delete(id);
  }

  public performInTrainerMutationTransaction(action: () => Promise<Trainer>): Promise<Trainer> {
    return this.transaction('rw', [this.trainers, this.mutations], action);
  }

  public performInStudentMutationTransaction(action: () => Promise<Student>): Promise<Student> {
    return this.transaction('rw', [this.students, this.mutations], action);
  }
}

const schoolAccessDatabase = new SchoolAccessDatabase();
export default schoolAccessDatabase;
