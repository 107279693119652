































































import { Component, Vue } from 'vue-property-decorator';
import MutationFactory from '@/localCache/mutations/MutationFactory';
import { StudentViewModel } from '@/model/StudentViewModel';
import SchoolCache from '@/localCache/SchoolCache';
import Vue2Filters from 'vue2-filters';
import { StudentMutator } from '@/api/model/StudentMutator';
import { PaymentMethod } from '@/api/model/PaymentMethod';
import { IMutation } from '@/localCache/mutations/IMutation';

@Component({
  name: 'PaidCourseDialog',
  mixins: [Vue2Filters.mixin],
})
export default class PaidCourseDialog extends Vue {
  private showDialog = false;
  private noOpenCosts = false;
  private openCosts = 0;
  private paymentType = 2;
  private mutation: IMutation | undefined;

  private student?: StudentViewModel;

  private showDialogPromiseResolve?: (value?: void | PromiseLike<void> | undefined) => void;

  private get headers() {
    return [
      { text: 'Bezahlt am', align: 'start', value: 'paidDate' },
      { text: 'Betrag', value: 'amount' },
      { text: 'Bezahlmethode', value: 'paidMethod' },
    ];
  }
  private get pagination() {
    return { sortBy: 'paidDate', descending: true, rowsPerPage: -1 };
  }

  public async show(student: StudentViewModel): Promise<void> {
    this.student = student;
    const unpaidUnits = StudentMutator.getUnpaidUnits(student.studentData);

    this.noOpenCosts = unpaidUnits.unitIds.length === 0;
    this.openCosts = unpaidUnits.amount;
    this.paymentType = 2;

    this.mutation = await SchoolCache.getMutationById(
      MutationFactory.getPaymentMutationId(student.studentData)
    );

    if (this.mutation !== undefined) {
      const data = this.mutation.data as {
        paymentType: PaymentMethod;
        paidUnits: string[];
      };
      if (data.paymentType === PaymentMethod.Cash) this.paymentType = 0;
      else if (data.paymentType === PaymentMethod.BankTransferN26) this.paymentType = 1;

      this.noOpenCosts = false;
      this.openCosts = StudentMutator.getAmountOfUnits(student.studentData, data.paidUnits);
    }

    this.showDialog = true;
    return new Promise(resolve => {
      this.showDialogPromiseResolve = resolve;
    });
  }

  private async paymentUpdated(): Promise<void> {
    if (!this.student) return;

    if (this.paymentType === 2) {
      // Remove exisiting
      await SchoolCache.removeMutationById(
        MutationFactory.getPaymentMutationId(this.student.studentData)
      );
    } else {
      // Add or change
      const paymentType =
        this.paymentType === 0 ? PaymentMethod.Cash : PaymentMethod.BankTransferN26;

      if (this.mutation !== undefined) {
        await SchoolCache.addMutation(
          MutationFactory.CreateStudentCoursePayedMutationFromExisting(
            this.student.studentData,
            paymentType,
            this.mutation
          )
        );
      } else {
        await SchoolCache.addMutation(
          MutationFactory.CreateStudentCoursePayedMutation(this.student.studentData, paymentType)
        );
      }
    }

    this.close();
  }

  private close(): void {
    if (this.showDialogPromiseResolve === null || this.showDialogPromiseResolve === undefined)
      return;

    this.showDialog = false;
    this.showDialogPromiseResolve();
  }
}
