import Course from '@/api/model/Course';
import { PresenceState } from '@/api/model/PresenceState';
import { Student } from '../../api/model/Student';
import moment, { Duration } from 'moment';
import { PaymentMethod } from '@/api/model/PaymentMethod';
import { StudentMutator } from '@/api/model/StudentMutator';
import { SubmittedDocumentType } from '@/api/model/SubmittedDocumentType';
import { EntityType, IMutation, MutationType } from './IMutation';
import {
  StudentPresenceMutation,
  StudentCovidDocumentMutation,
  StudentFloatyMutation,
  StudentUpdatePayment,
  StudentPaymentMutation,
  StudentBadgeMutation,
} from './student/StudentMutation';
import { Trainer } from '@/api/model/Trainer';
import { TrainerCovidDocumentMutation, TrainerPresenceMutation } from './trainer/TrainerMutation';
import { Interval } from '@/api/model/Interval';
import { TrainerMutator } from '@/api/model/TrainerMutator';
import { Experience, getExperienceString } from '@/api/model/Experience';
import schoolCache from '../SchoolCache';
import Badge from '@/api/model/Badge';

export default class MutationFactory {
  public static CreateStudentPresenceMutation(
    student: Student,
    sessionDate: Date,
    course: Course,
    presence: PresenceState
  ): IMutation {
    const msg = `Anwesend am ${moment(sessionDate).format('DD.MM.YYYY')} -> ${presence as string}`;

    const id = `${student.id}:${MutationType.Present}:${sessionDate.getTime()}`;

    const data = {
      courseId: course.id,
      date: sessionDate,
      presence: presence,
      originalPresence: StudentMutator.getPresence(student, course.id, sessionDate.getTime()),
    };

    const existing = schoolCache.getMutationById(id);

    return StudentPresenceMutation.create(id, student.id, this.GetStudentName(student), msg, data);
  }

  public static CreateTrainerPresenceMutation(
    trainer: Trainer,
    sessionDate: Date,
    workTime: Interval<Duration>[]
  ): IMutation {
    const msg = `Trainiert am ${moment(sessionDate).format('DD.MM.YYYY')}`;

    const data = {
      sessionDate: sessionDate,
      workTime: workTime,
      originalWorkTime: TrainerMutator.getWorkTime(trainer, sessionDate),
    };

    const id = `${trainer.id}:${MutationType.Present}:${sessionDate.getTime()}`;
    return TrainerPresenceMutation.create(id, trainer.id, this.GetTrainerName(trainer), msg, data);
  }

  public static CreateStudentCovidDocumentMutation(student: Student, submitDate: Date): IMutation {
    const msg = `Covid Dokumente erhalten am ${moment(submitDate).format('DD.MM.YYYY')}`;

    const data = {
      submitDate: submitDate,
      documentType: SubmittedDocumentType.Covid19Document,
    };

    return StudentCovidDocumentMutation.create(
      this.getStudentCovidSubmittedMutationId(student, submitDate),
      student.id,
      this.GetStudentName(student),
      msg,
      data
    );
  }

  public static CreateTrainerCovidDocumentMutation(trainer: Trainer, submitDate: Date): IMutation {
    const msg = `Covid Dokumente erhalten am ${moment(submitDate).format('DD.MM.YYYY')}`;

    const data = {
      submitDate: submitDate,
      documentType: SubmittedDocumentType.Covid19Document,
    };

    return TrainerCovidDocumentMutation.create(
      this.getTrainerCovidSubmittedMutationId(trainer, submitDate),
      trainer.id,
      this.GetTrainerName(trainer),
      msg,
      data
    );
  }

  public static getStudentFloatyMutationId(student: Student): string {
    return `${student.id}:${MutationType.FloatyCount}`;
  }

  public static CreateStudentFloatyMutation(student: Student, floatyCount: number): IMutation {
    const msg = `Schwimmhilfen -> ${floatyCount}`;

    const data = {
      originalFloatyCount: StudentMutator.getFloaty(student),
      floatyCount: floatyCount,
    };

    return StudentFloatyMutation.create(
      this.getStudentFloatyMutationId(student),
      student.id,
      this.GetStudentName(student),
      msg,
      data
    );
  }

  public static CreateStudentFloatyMutationFromExisting(
    student: Student,
    originalData: unknown,
    floatyCount: number
  ) {
    const msg = `Schwimmhilfen -> ${floatyCount}`;
    const originalStudent = originalData as {
      originalFloatyCount: number;
    };

    const data = {
      originalFloatyCount: originalStudent.originalFloatyCount,
      floatyCount: floatyCount,
    };

    return StudentFloatyMutation.create(
      this.getStudentFloatyMutationId(student),
      student.id,
      this.GetStudentName(student),
      msg,
      data
    );
  }

  public static async CreateStudentBadgeMutation(
    student: Student,
    newBadges: Badge[]
  ): Promise<IMutation> {
    const msg = `Abzeichen geändert`;
    const id = `${student.id}:${MutationType.Badge}`;

    const originalStudent = await this.RevertExistingMutation<Student>(id);

    const data = {
      badges: newBadges,
      originalBadges: (originalStudent ?? student).badges,
    };

    const mutation = StudentBadgeMutation.create(
      id,
      student.id,
      this.GetStudentName(student),
      msg,
      data
    );
    await schoolCache.addMutation(mutation);

    return mutation;
  }

  private static async RevertExistingMutation<T>(id: string): Promise<T | undefined> {
    const originalMutation = await schoolCache.getMutationById(id);

    if (!originalMutation) return undefined;

    return (await originalMutation.remove()) as T;
  }

  public static CreateStudentCoursePayedMutationFromExisting(
    student: Student,
    method: PaymentMethod,
    mutation: IMutation
  ): IMutation {
    const oldData = mutation.data as StudentUpdatePayment;

    const msg = `${StudentMutator.getAmountOfUnits(student, oldData.paidUnits)} € bezahlt mit ${
      PaymentMethod[method]
    }`;

    const data = {
      paymentType: method,
      paidUnits: oldData.paidUnits,
    };

    const id = `${student.id}:${MutationType.Payment}`;
    return StudentPaymentMutation.create(id, student.id, this.GetStudentName(student), msg, data);
  }

  public static CreateStudentCoursePayedMutation(
    student: Student,
    method: PaymentMethod
  ): IMutation {
    const openUnits = StudentMutator.getUnpaidUnits(student);
    if (openUnits.unitIds.length === 0) throw Error('Es gibts keine offenen Posten.');

    const msg = `${openUnits.amount} € bezahlt mit ${PaymentMethod[method]}`;

    const data = {
      paymentType: method,
      paidUnits: openUnits.unitIds,
    };

    const id = `${student.id}:${MutationType.Payment}`;
    return StudentPaymentMutation.create(id, student.id, this.GetStudentName(student), msg, data);
  }

  public static getTrainerCovidSubmittedMutationId(trainer: Trainer, submitDate: Date): string {
    return this.getCovidSubmittedMutationId(trainer.id, EntityType.Trainer, submitDate);
  }

  public static getStudentCovidSubmittedMutationId(student: Student, submitDate: Date): string {
    return this.getCovidSubmittedMutationId(student.id, EntityType.Student, submitDate);
  }

  public static getCovidSubmittedMutationId(
    entityId: string,
    entityType: EntityType,
    submitDate: Date
  ): string {
    return `${entityId}:${entityType}:${MutationType.CovidDocument}:${
      SubmittedDocumentType.Covid19Document
    }:${submitDate.getTime()}`;
  }

  public static getPaymentMutationId(student: Student): string {
    return `${student.id}:${MutationType.Payment}`;
  }

  private static GetTrainerName(trainer: Trainer): string {
    return ` ${trainer.contact.firstname} ${trainer.contact.lastname}`;
  }

  private static GetStudentName(student: Student): string {
    return ` ${student.firstname} ${student.lastname}`;
  }
}
