export enum PaymentMethod {
  None = 'None',
  Graceful = 'Graceful',
  Cash = 'Cash',
  Card = 'Card',
  BankTransfer = 'BankTransfer',
  PayPal = 'PayPal',
  BankTransferN26 = 'BankTransferN26',
  BankTransferHolvi = 'BankTransferHolvi',
}
