import { Student } from './Student';
import _ from 'lodash';
import { PresenceState } from './PresenceState';

export default interface UnitUsage {
  courseType: number;

  paidSessionCount: number;
  openSessionCount: number;
  customerOpenSessionCount: number;
  totalSessionCount: number;
}

function calculateSingleUsage(courseType: number, student: Student): UnitUsage {
  const result = {
    courseType: courseType,

    paidSessionCount: 0,
    openSessionCount: 0,
    customerOpenSessionCount: 0,
    totalSessionCount: 0,
  };

  const u = _(student.units)
    .filter(u => u.courseType === courseType)
    .value();
  const uLength = u.length;
  let uIter = -1;

  let leftDays = 0;
  let leftPaidSickDays = 0;

  for (const s of _(student.takeSessions)
    .filter(ts => ts.courseType === courseType)
    .value()) {
    if (leftDays <= 0) {
      result.paidSessionCount -= leftPaidSickDays;
      leftPaidSickDays = 0;

      if (uIter < uLength - 1) {
        uIter += 1;
        leftDays += u[uIter].size;

        // payed sick days do not sum up.
        leftPaidSickDays = u[uIter].payedSickDays;
        result.paidSessionCount += u[uIter].size + u[uIter].payedSickDays;
      }
    }

    if (s.isSkipped) continue;

    result.totalSessionCount++;

    if (s.presence === PresenceState.Unknown) continue;

    if (s.presence === PresenceState.Yes) {
      leftDays--;
    } else {
      if (leftPaidSickDays > 0) leftPaidSickDays--;
      else leftDays--;
    }
  }

  // Unit is completely used, Paid Sick days will not add up
  if (leftDays <= 0) {
    result.paidSessionCount -= leftPaidSickDays;
    leftPaidSickDays = 0;
  }

  result.openSessionCount = leftPaidSickDays + leftDays;
  result.customerOpenSessionCount = leftDays;

  let takeNextCurrent = leftDays == 0;

  while (uIter < uLength - 1) {
    uIter += 1;
    if (takeNextCurrent) takeNextCurrent = false;

    const sum = u[uIter].size + u[uIter].payedSickDays;

    result.customerOpenSessionCount += u[uIter].size;
    result.openSessionCount += sum;
    result.paidSessionCount += sum;
  }

  return result;
}

export function calculateUsage(student: Student): { [id: number]: UnitUsage } {
  return _(student.units)
    .map(u => u.courseType)
    .uniq()
    .map(usageType => calculateSingleUsage(usageType, student))
    .keyBy(u => u.courseType)
    .value();
}
