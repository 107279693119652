import _ from 'lodash';
import { Duration } from 'moment';
import { Interval } from './Interval';
import { IntervalSet } from './IntervalSet';
import { SubmittedDocumentType } from './SubmittedDocumentType';
import { Trainer } from './Trainer';

export class TrainerMutator {
  public static getWorkTimeAsString(trainer: Trainer, dateTime: Date): string {
    const session = _(trainer.holdSessions).find(ts => ts.date.getTime() === dateTime.getTime());
    if (session === undefined || session.workingTime.length === 0) return 'Nicht anwesend';

    const intervalSet = new IntervalSet();
    for (const w of session.workingTime) intervalSet.add(new Interval<Duration>(w.from, w.to));

    return _(intervalSet.entities)
      .sortBy(w => w.from.milliseconds)
      .map(w => `${w.from.format('hh:mm')} - ${w.to.format('hh:mm')}`)
      .value()
      .join(' | ');
  }

  public static getWorkTime(trainer: Trainer, dateTime: Date): Interval<Duration>[] {
    const session = _(trainer.holdSessions).find(ts => ts.date.getTime() === dateTime.getTime());

    if (session !== undefined) return session.workingTime;

    return [];
  }

  public static setWorkTime(
    trainer: Trainer,
    dateTime: Date,
    workTime: Interval<Duration>[]
  ): void {
    const session = _(trainer.holdSessions).find(ts => ts.date.getTime() === dateTime.getTime());
    if (session === undefined) {
      trainer.holdSessions.push({
        id: '',
        date: dateTime,
        isSkipped: false,
        workingTime: workTime,
      });
    } else {
      session.workingTime = workTime;
    }
  }

  public static removeSubmittedDocument(
    trainer: Trainer,
    submitDate: Date,
    type: SubmittedDocumentType
  ): void {
    trainer.submittedDocuments = _(trainer.submittedDocuments)
      .filter(s => s.date.getTime() !== submitDate.getTime() || s.documentType !== type)
      .value();
  }

  public static addSubmittedDocument(
    trainer: Trainer,
    submitDate: Date,
    type: SubmittedDocumentType
  ): void {
    trainer.submittedDocuments.push({
      date: submitDate,
      documentType: type,
    });
  }
}
