import { Experience } from '../api/model/Experience';
import IContact from '../api/model/Contact';
import Badge from '../api/model/Badge';
import Unit from '../api/model/Unit';
import JoinedCourse from '../api/model/JoinedCourse';
import TakeSession from '../api/model/TakeSession';
import SubmittedDocument from '../api/model/SubmittedDocument';
import UnitUsage from '../api/model/UnitUsage';
import { Student } from '../api/model/Student';
import { Covid19DocumentManager } from './Covid19DocumentManager';
import { PaymentMethod } from '../api/model/PaymentMethod';
import _ from 'lodash';
import { globalEvent } from '@billjs/event-emitter';
import { BadgeType, BadgesOrder } from '@/api/model/BadgeType';

export class StudentViewModel {
  public studentData: Student;

  public get name(): string {
    return this.studentData.firstname + ' ' + this.studentData.lastname;
  }
  public get firstname(): string {
    return this.studentData.firstname;
  }
  public get lastname(): string {
    return this.studentData.lastname;
  }
  public get birthday(): Date | undefined {
    return this.studentData.birthday;
  }
  public get contact(): IContact {
    return this.studentData.contact;
  }
  public get announcementDate(): Date {
    return this.studentData.announcementDate;
  }
  public get isDeleted(): boolean {
    return this.studentData.isDeleted;
  }
  public get isActive(): boolean {
    return this.studentData.isActive;
  }
  public badge: string = BadgeType.None;

  public get badges(): Badge[] {
    return this.studentData.badges;
  }
  public get units(): Unit[] {
    return this.studentData.units;
  }
  public get courses(): JoinedCourse[] {
    return this.studentData.courses;
  }
  public get takeSessions(): TakeSession[] {
    return this.studentData.takeSessions;
  }
  public get submittedDocuments(): SubmittedDocument[] {
    return this.studentData.submittedDocuments;
  }
  public get unitUsage(): { [id: number]: UnitUsage } {
    return this.studentData.unitUsage;
  }
  public amount = 0;
  public remainedSessionCount = 0;
  public courseCount = 0;

  public covidDocuments: Covid19DocumentManager = new Covid19DocumentManager();

  constructor(studentData: Student) {
    this.studentData = studentData;
    this.update(studentData);
  }

  public update(studentData: Student): void {
    this.studentData = studentData;
    this.covidDocuments.Update(this.studentData.submittedDocuments);
    this.amount = _(this.studentData.units)
      .filter(u => u.paidMethod === PaymentMethod.None)
      .sumBy(u => u.amount);

    this.courseCount = this.studentData.units.length;

    this.remainedSessionCount = _(this.studentData.unitUsage)
      .values()
      .sumBy(u => u.customerOpenSessionCount);

    this.badge =
      BadgesOrder[
        _(this.studentData.badges)
          .map(b => BadgesOrder.indexOf(b.badgeType as BadgeType))
          .max() as number
      ] ?? BadgeType.None;

    const eventId = `student-${studentData.id}`;
    globalEvent.fire(eventId, '');
  }
}
