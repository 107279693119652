
























































































import { Component, Vue } from 'vue-property-decorator';
import schoolCache from '../localCache/SchoolCache';
import moment from 'moment';
import { IMutation } from '@/localCache/mutations/IMutation';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

@Component({
  name: 'SyncView',
  filters: {
    formatDate(value: unknown) {
      if (value === undefined) return '';
      if (value instanceof Date) return moment(value).format('hh:mm DD.MM.YYYY');
    },
  },
  components: {
    ConfirmDialog,
  },
})
export default class SyncView extends Vue {
  $refs!: {
    confirmDialog: ConfirmDialog;
  };
  private search = '';
  private mutations: IMutation[] = [];
  private cache = schoolCache;

  private get headers() {
    return [
      { text: 'Name', align: 'start', value: 'entityName' },
      { text: 'Action', align: 'start', value: 'message' },
      { text: 'Datum', value: 'sortDate' },
      { text: 'Synchronisiert', value: 'isSynchronized' },
      { text: 'Actions', value: 'action' },
    ];
  }

  private async syncCache(): Promise<void> {
    caches.keys().then(names => {
      for (let name of names) caches.delete(name);
    });

    await this.cache.syncCache();
    this.mutations = await this.cache.getAllMutations();
  }

  private async wipe(): Promise<void> {
    if (
      !(await this.$refs.confirmDialog.show(
        'Alles wird gelöscht!!! Auch die nicht synchronisierten Änderungen. Sicher?'
      ))
    )
      return;

    if (!(await this.$refs.confirmDialog.show('Sicher Sicher?'))) return;

    this.cache.wipeCache();

    caches.keys().then(names => {
      for (let name of names) caches.delete(name);
    });
    window.location.reload();
  }

  private async forceReload(): Promise<void> {
    if (
      !(await this.$refs.confirmDialog.show(
        'Es wird eine Internet verbindung benötigt!!! Sicher neu laden?'
      ))
    )
      return;
    caches.keys().then(names => {
      for (let name of names) caches.delete(name);
    });
    window.location.reload();
  }

  private async clearSyncedMutations(): Promise<void> {
    await this.cache.clearSyncedMutations();
    this.mutations = await this.cache.getAllMutations();
  }

  private async activated(): Promise<void> {
    this.mutations = await this.cache.getAllMutations();
  }
  private async removeMutation(m: IMutation): Promise<void> {
    await this.cache.removeMutation(m);
    this.mutations = await this.cache.getAllMutations();
  }
}
