



































import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { Duration } from 'moment';
import { Interval } from '@/api/model/Interval';
import moment from 'moment';
import { TrainerMutator } from '@/api/model/TrainerMutator';
import { PresenceTrainerViewModel } from '@/model/PresenceTrainerViewModel';

@Component({
  name: 'TrainerWorkTimeDialog',
  filters: {
    formatDate(value: unknown) {
      if (!value) {
        return '';
      }
      if (value instanceof Date) {
        return moment(value).format('DD.MM.YYYY');
      }
    },
  },
})
export default class TrainerWorkTimeDialog extends Vue {
  private showDialog = false;
  private trainer?: PresenceTrainerViewModel;
  private date: Date = new Date();
  private selectedWorkTime: number[] = [];
  private avaliableWorktime: string[] = [];
  private avaliableWorktimeData: Interval<Duration>[] = [];

  private showDialogPromiseResolve?: (value?: void | PromiseLike<void> | undefined) => void;

  private showPhone(number: string): boolean {
    if (!number) return false;
    if (number === '' || number.length <= 1) return false;
    if (number === 'Nicht angegeben') return false;
    if (number === 'N/A') return false;

    return true;
  }

  public show(
    trainer: PresenceTrainerViewModel,
    date: Date,
    avaliableWorktime: Interval<Duration>[]
  ): Promise<void> {
    this.trainer = trainer;
    this.date = date;
    this.avaliableWorktimeData = avaliableWorktime;
    this.avaliableWorktime = _(avaliableWorktime)
      .map(w => w.from.format('HH:mm') + ' - ' + w.to.format('HH:mm'))
      .value();

    this.selectedWorkTime = [];

    const trainerWorkedTime = _(TrainerMutator.getWorkTime(trainer.trainer.trainerData, date))
      .map(w => w.from.format('HH:mm') + ' - ' + w.to.format('HH:mm'))
      .value();

    for (let i = 0; i < avaliableWorktime.length; i++) {
      if (_.includes(trainerWorkedTime, this.avaliableWorktime[i])) {
        this.selectedWorkTime.push(i);
      }
    }

    this.showDialog = true;
    return new Promise(resolve => {
      this.showDialogPromiseResolve = resolve;
    });
  }
  private selectNone(): void {
    this.selectedWorkTime = [];
  }

  private selectAll(): void {
    this.selectedWorkTime = [...Array(this.avaliableWorktime.length).keys()];
  }

  private async save(): Promise<void> {
    const workTime = _(this.selectedWorkTime)
      .map(i => this.avaliableWorktimeData[i])
      .value();

    this.trainer?.updateWorkingTime(workTime);
    this.close();
  }
  private async close(): Promise<void> {
    if (this.showDialogPromiseResolve === null || this.showDialogPromiseResolve === undefined)
      return;

    this.showDialog = false;
    this.trainer = undefined;
    this.showDialogPromiseResolve();
  }
}
