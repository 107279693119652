export enum Experience {
  Seahorse = 'Seahorse',
  Bronze = 'Bronze',
  Toddler = 'Toddler',
  Adult = 'Adult',
  Silver = 'Silver',
  Gold = 'Gold',
  Skull1 = 'Skull1',
  Skull1Dot5 = 'Skull1Dot5',
  Skull2 = 'Skull2',
  Intensive = 'Intensive',
  Unknown = 'Unknown',
}

const ExperienceName = new Map<Experience, string>([
  [Experience.Seahorse, 'Seepferdchen'],
  [Experience.Bronze, 'Bronze'],
  [Experience.Toddler, 'Wassergewöhnung'],
  [Experience.Adult, 'Erwachsen'],
  [Experience.Silver, 'Silber'],
  [Experience.Gold, 'Gold'],
  [Experience.Skull1, 'Totenkopf 1h'],
  [Experience.Skull1Dot5, 'Totenkopf 1.5h'],
  [Experience.Skull2, 'Totenkopf 2h'],
  [Experience.Intensive, 'Intensive'],
]);

export function getExperienceString(e: Experience): string {
  let name = ExperienceName.get(e);
  if (!name) name = ExperienceName.get(Experience[e]);

  if (!name) return 'Nicht definiert.';

  return name;
}
