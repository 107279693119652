





























































import { Component, Vue } from 'vue-property-decorator';
import DatePickerDialog from '../components/DatePickerDialog.vue';
import TrainerDetailDialog from '../components/TrainerDetailDialog.vue';
import TrainerWorkTimeDialog from '../components/TrainerWorkTimeDialog.vue';

import Vue2Filters from 'vue2-filters';
import 'moment-duration-format';
import moment, { Duration } from 'moment';
import schoolCache from '../localCache/SchoolCache';
import { PresenceTrainerViewModel } from '@/model/PresenceTrainerViewModel';
import { Interval } from '@/api/model/Interval';
import { DateHelper } from '@/helper/DateHelper';

@Component({
  name: 'TrainerView',
  mixins: [Vue2Filters.mixin],
  components: {
    DatePickerDialog,
    TrainerDetailDialog,
    TrainerWorkTimeDialog,
  },
  filters: {
    formatDate(value: unknown) {
      if (!value) {
        return '';
      }
      if (value instanceof Date) {
        return moment(value).format('DD.MM.YYYY');
      }
    },
    formatTime(value: unknown) {
      if (!value) {
        return '';
      }
      if (moment.isDuration(value)) {
        return value.format('hh:mm');
      }
    },
  },
})
export default class TrainerView extends Vue {
  $refs!: {
    datePickerDialog: DatePickerDialog;
    trainerDetailDialog: TrainerDetailDialog;
    trainerWorkTimeDialog: TrainerWorkTimeDialog;
  };

  private updatingDate = false;
  private showSelectDialog = false;
  private selectedDate: Date = new Date(Date.now());
  private allTrainers: PresenceTrainerViewModel[] = [];
  private avaliableWorkTime: Interval<Duration>[] = [];
  private search = '';

  private get headers() {
    return [
      { text: 'Name', align: 'start', value: 'trainer.name' },
      { text: 'Arbeitszeit', value: 'workTime' },
      {
        text: 'Covid Dokumente',
        value: 'covidDocuments.sortablelastSubmittedCovid19Document',
      },
      { text: 'Actions', value: 'action', sortable: false, align: 'right' },
    ];
  }

  private async activated(): Promise<void> {
    await this.updateSelection(this.selectedDate);
  }

  private async selectDate(): Promise<void> {
    return this.$refs.datePickerDialog.show().then(selectedDate => {
      if (selectedDate !== undefined) this.updateSelection(selectedDate);
    });
  }

  private async updateSelection(selectedDate: Date): Promise<void> {
    this.selectedDate = DateHelper.onlyDate(selectedDate);

    this.allTrainers = await schoolCache.getPresenceTainers(selectedDate);
    this.avaliableWorkTime = await schoolCache.getSessionTimes(selectedDate);
  }

  private async showDetails(trainer: PresenceTrainerViewModel): Promise<void> {
    await this.$refs.trainerDetailDialog.show(trainer.trainer);
  }
  private async editWorkTime(trainer: PresenceTrainerViewModel): Promise<void> {
    await this.$refs.trainerWorkTimeDialog.show(trainer, trainer.date, this.avaliableWorkTime);
  }
  private SubmitCovidDocument(trainer: PresenceTrainerViewModel): Promise<void> {
    return trainer.toggleSubmittedCovidDocument();
  }
}
