import { TrainerMutator } from '@/api/model/TrainerMutator';
import _ from 'lodash';
import 'moment-duration-format';
import { PresenceCovid19DocumentManager } from './PresenceCovid19DocumentManager';
import { TrainerViewModel } from './TrainerViewModel';
import SchoolCache from '@/localCache/SchoolCache';
import MutationFactory from '@/localCache/mutations/MutationFactory';
import { Interval } from '@/api/model/Interval';
import { Duration } from 'moment';
import { globalEvent } from '@billjs/event-emitter';

export class PresenceTrainerViewModel {
  public trainer: TrainerViewModel;
  public date: Date;
  public workTime = '';

  public covidDocuments: PresenceCovid19DocumentManager;

  private eventId: string;

  constructor(trainer: TrainerViewModel, date: Date) {
    this.trainer = trainer;
    this.date = date;
    this.eventId = `trainer-${trainer.trainerData.id}`;

    this.covidDocuments = new PresenceCovid19DocumentManager(
      this.trainer.covidDocuments,
      this.date
    );

    this.update();
    globalEvent.on(this.eventId, () => {
      this.update();
    });
  }

  public async toggleSubmittedCovidDocument(): Promise<void> {
    const existingMutation = await SchoolCache.getMutationById(
      MutationFactory.getTrainerCovidSubmittedMutationId(this.trainer.trainerData, this.date)
    );

    if (existingMutation !== undefined) {
      // Remove current mutation
      await SchoolCache.removeMutation(existingMutation);
    } else {
      // No mutation and valid, means, no change here!
      if (this.covidDocuments.submittedCovid19DocumentValid) return;

      const mutation = MutationFactory.CreateTrainerCovidDocumentMutation(
        this.trainer.trainerData,
        this.date
      );

      await SchoolCache.addMutation(mutation);
    }

    this.covidDocuments.update(this.date);
  }

  public async updateWorkingTime(workTime: Interval<Duration>[]): Promise<void> {
    const m = MutationFactory.CreateTrainerPresenceMutation(
      this.trainer.trainerData,
      this.date,
      workTime
    );
    await SchoolCache.addMutation(m);

    this.update();
  }

  private update(): void {
    this.workTime = TrainerMutator.getWorkTimeAsString(this.trainer.trainerData, this.date);
  }
}
