import Vue from 'vue';
import VueRouter from 'vue-router';
import AllStudentsView from '../views/AllStudentsView.vue';
import CourseView from '../views/CourseView.vue';
import TrainerView from '../views/TrainerView.vue';
import LoginView from '../views/LoginView.vue';
import SyncView from '../views/SyncView.vue';
import auth from '../store/modules/auth';

Vue.use(VueRouter);

const publicPages = ['/login'];

const routes = [
  {
    path: '/',
    name: 'default',
    component: CourseView,
  },
  {
    path: '/allstudents',
    name: 'all-students',
    component: AllStudentsView,
  },
  {
    path: '/login',
    name: 'login-view',
    component: LoginView,
  },
  {
    path: '/trainers',
    name: 'trainer-view',
    component: TrainerView,
  },
  {
    path: '/sync',
    name: 'sync-view',
    component: SyncView,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !auth.loggedIn) return next('/login');

  next();
});

export default router;
