import { Interval } from '@/api/model/Interval';

export class NonMergingIntervalDateSet {
  public entities: Interval<number>[] = [];

  public clear(): void {
    this.entities = [];
  }

  // Add a workload
  public add(interval: Interval<number>): void {
    this.entities.push(interval);
    this.entities.sort((a, b) => a.from - b.from);
  }

  public include(date: Date): boolean {
    return this.includingInterval(date) !== undefined;
  }

  public includingInterval(date: Date): Interval<number> | undefined {
    const time = date.getTime();

    return this.entities.find(i => i.include(time));
  }
}
